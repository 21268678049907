import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Table from '@frontend/ui-kit/Components/Table';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Link from '@frontend/ui-kit/Components/Link';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import MultipleChoice from '../MultipleChoice';
import withPopup from '../../../HOC/withPopup';
import {requestCompanies} from '../../../actions/general';
import {requestCoreCarriers} from '../../../actions/shared';
import {
    requestPlanManagerPlans,
    requestPlanManagerPlanNames,
    requestPlanManagerPlanDeleting,
    receivePlanManagerPlans
} from '../../../actions/adminPortal';
import {getMultipleChoiceValues, getPlanManagerPlans} from '../../../selectors/adminPortal';
import {getActiveCompany, getCompaniesMap, getProfileInfo} from '../../../selectors/general';
import {PLAN_KIND_OPTIONS, PLAN_YEAR_OPTIONS} from '../../../options';
import {isEmpty, promisifyAsyncFunction} from '../../../utils';
import useSortByText from '../../../hooks/useSortByText';
import {ROUTES} from '../../../constants';
import './index.scss';

const POPUP_ID = 'planManagerDeletionPopup';
const PER_PAGE_LIMIT = 50;
const MULTIPLE_CHOICE_NAME = 'planManagerLayout';

const PlanManagerLayout = ({openPopup, closePopup}) => {
    const [plans, chosenValues, activeCompany] = useSelector(state => [getPlanManagerPlans(state), getMultipleChoiceValues(state, MULTIPLE_CHOICE_NAME), getActiveCompany(state)]);
    const {alias: companyAlias, name: companyName} = activeCompany || {};
    const companies = useSelector(getCompaniesMap) || [];
    const {is_implementation_manager: isImplementationManager, is_implementation_specialist: isImplementationSpecialist} = useSelector(getProfileInfo);
    const isCompanySearch = isEmpty(chosenValues) && companyAlias;
    const dispatch = useDispatch();
    const sortByText = useSortByText();
    const [offset, setOffset] = useState(0);
    const [isNextPageEmpty, setIsNextPageEmpty] = useState(true);

    const requestPlans = useCallback(async ({company_name, company_alias, ...baseQuery}) => {
        const filteredQuery = Object.entries(baseQuery).filter(([key, value]) => value !== '');
        const query = Object.fromEntries(filteredQuery);
        const params = {
            ...query,
            ...(company_alias || company_name) && {company_alias: companies[company_name]?.alias || companyAlias},
            with_related: 'company',
            limit: PER_PAGE_LIMIT,
            offset: offset * PER_PAGE_LIMIT
        };

        const [{data: plans}, {data: nextPagePlans}] = await Promise.all([
            dispatch(requestPlanManagerPlans(params)),
            // FYI: get next page to determine is next page available (21.04.22, Yurii)
            dispatch(requestPlanManagerPlans({...params, offset: (offset + 1) * PER_PAGE_LIMIT}))
        ]);

        setIsNextPageEmpty(!nextPagePlans.length);
        dispatch(receivePlanManagerPlans(plans));
    }, [dispatch, companies, offset]);

    useEffect(() => {
        // FYI: If we moved to Plan Manager from the Dashboard, the user should get default plan searching by active company (business logic) (Pasha, 27.10.2021)
        requestPlans(isCompanySearch ? {company_alias: companyAlias} : chosenValues);
    }, [offset]);

    const loadCoreCarriersOptions = promisifyAsyncFunction(async query => {
        const carriers = await dispatch(requestCoreCarriers(query));

        return carriers.map(({issuer_name: issuerName}) => ({label: issuerName, value: issuerName}));
    });

    const loadPlanNameOptions = promisifyAsyncFunction(async name => {
        const {company_name: companyName} = chosenValues || {};
        const params = {
            name,
            offset: 0,
            with_related: 'company',
            ...companyName && {company_alias: companies[companyName]?.alias || companyAlias}
        };
        const {planNames} = await dispatch(requestPlanManagerPlanNames(params));

        return planNames.map(name => ({label: name, value: name}));
    });

    const loadCompanyOptions = promisifyAsyncFunction(async query => {
        const {companies} = await dispatch(requestCompanies(query));

        return companies.map(({name: value}) => ({label: value, value}));
    });

    const onDeletePlan = id => {
        const onDelete = async () => {
            closePopup();
            const {isSuccess} = await dispatch(requestPlanManagerPlanDeleting(id));

            if (!isSuccess) {
                return false;
            }

            requestPlans(chosenValues);
        };

        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button type={BUTTON_TYPES.destructive} onClick={onDelete}>Delete</Button>
            </React.Fragment>
        );
        const popupContent = <Text className='plan-manager-deletion-popup'>This item will be deleted permanently. You will not be able to undo this action.</Text>;

        const popupProps = {title: 'Are you sure you want to delete this plan?', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const getTableColumns = () => {
        const getManageBar = ({value: id}) => {
            const onDelete = () => onDeletePlan(id);

            return (
                <React.Fragment>
                    <Link className='plans-table-link' href={`${ROUTES.planManager}/${id}`}>
                        <Icon className='plans-table-icon' type={ICON_TYPES.edit}/>
                    </Link>
                    {(isImplementationManager || isImplementationSpecialist) && (
                        <Icon className='plans-table-icon' type={ICON_TYPES.delete} onClick={onDelete}/>
                    )}

                </React.Fragment>
            );
        };

        const getPlanStatus = ({value: active}) => {
            const stickerProps = {
                type: active ? STICKER_TYPES.success : STICKER_TYPES.default,
                children: active ? 'Active' : 'Deactivated'
            };

            return <Sticker {...stickerProps}/>;
        };

        return [
            {Header: 'Company', accessor: 'company_name', sortType: sortByText},
            {Header: 'Plan ID', accessor: 'plan_id', width: 300, sortType: sortByText},
            {Header: 'Plan Status', accessor: 'active', Cell: getPlanStatus},
            {Header: 'Plan Name', accessor: 'name', sortType: sortByText},
            {Header: 'Carrier', accessor: 'issuer_name', sortType: sortByText},
            {Header: 'Plan Type', accessor: 'type'},
            {Header: 'Plan Category', accessor: 'plan_kind'},
            {Header: 'SFTP Name', accessor: 'display_name', sortType: sortByText},
            {Header: 'Manage', accessor: 'id', Cell: getManageBar}
        ];
    };

    const applyFilter = useCallback(query => {
        setOffset(0);
        requestPlans(query);
    }, [requestPlans]);

    const multipleChoiceProps = {
        choices: [
            {name: 'All', type: 'all'},
            {name: 'Year', type: 'year', options: PLAN_YEAR_OPTIONS},
            {name: 'Plan Category', type: 'plan_kinds', options: PLAN_KIND_OPTIONS},
            {name: 'Plan ID', type: 'plan_id'},
            {name: 'Plan Name', type: 'name', loadOptions: loadPlanNameOptions},
            {name: 'Carrier', type: 'issuer_name', loadOptions: loadCoreCarriersOptions},
            {name: 'Company', type: 'company_name', loadOptions: loadCompanyOptions}
        ],
        defaultValues: isCompanySearch && {company_name: companyName},
        onChange: applyFilter,
        name: MULTIPLE_CHOICE_NAME
    };

    const tableProps = {
        data: plans,
        isFilterable: false,
        columns: getTableColumns(),
        tableConfig: {
            autoResetSortBy: false
        }
    };

    return (
        <div className='plan-manager'>
            <Heading className='mb-20' type={HEADING_TYPES['1']}>Plan Manager</Heading>

            <div className='plans-table-header'>
                <Heading className='plans-table-header__title' type={HEADING_TYPES['4']}>Search</Heading>
                <ContentSection className='plans-table-header__content-section'>
                    <MultipleChoice {...multipleChoiceProps}/>
                </ContentSection>
            </div>

            <ContentSection>
                <Table {...tableProps}/>

                <div className='plan-manager__pagination'>
                    <Button disabled={offset === 0} type={BUTTON_TYPES.tertiary} className='pagination__button' iconLeft={<Icon type={ICON_TYPES.chevronLeft}/>} onClick={() => setOffset(offset => offset - 1)}>
                        Previous
                    </Button>
                    <Button disabled={isNextPageEmpty} type={BUTTON_TYPES.tertiary} className='pagination__button' iconRight={<Icon type={ICON_TYPES.chevronRight}/>} onClick={() => setOffset(offset => offset + 1)}>
                        Next
                    </Button>
                </div>
            </ContentSection>
        </div>
    );
};

PlanManagerLayout.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {PlanManagerLayout as TestablePlanManagerLayout};
export default withPopup(POPUP_ID)(PlanManagerLayout);

import {RECEIVE_REPORTING_INFO, SET_REPORTING_PLAN_PERIOD} from '../actions/actionTypes';
import {LOCAL_STORAGE_ITEMS, REPORTING_PLAN_PERIODS} from '../constants';

export const initialState = {
    planPeriod: localStorage.getItem(LOCAL_STORAGE_ITEMS.reportingPlanPeriod) || REPORTING_PLAN_PERIODS.year,
    info: {
        member_activation: {},
        reports: []
    }
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case SET_REPORTING_PLAN_PERIOD: {
            const {planPeriod} = payload;

            return {...state, planPeriod};
        }

        case RECEIVE_REPORTING_INFO: {
            const info = {...state.info, ...payload.info};

            return {...state, info};
        }

        default: {
            return state;
        }
    }
};

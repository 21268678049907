import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import ProgressBar from '@frontend/ui-kit/Components/ProgressBar';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import TPAAutoimportGroupConfig from '../TPAAutoimportGroupConfig';
import IndividualImportUploadFileForm from '../IndividualImportUploadFileForm';
import {requestAppConfiguration} from '../../../actions/adminPortal';
import {equal, getEqual, parseQuery} from '../../../utils';
import {IMPORT_CONFIG_TYPES} from '../../../constants';

const WIZARD_STEPS = [
    {activeStep: 0, StepComponent: TPAAutoimportGroupConfig, stepName: 'configuration', stepTitle: 'Configure'},
    {activeStep: 1, StepComponent: IndividualImportUploadFileForm, stepName: 'upload_file', stepTitle: 'Upload File'}
];

const TPAAutoimportGroup = () => {
    const dispatch = useDispatch();
    const {group_alias: groupAlias} = useParams();
    const {search, hash} = useLocation();
    const navigate = useNavigate();
    const {group_name: groupName} = parseQuery(search);
    const [configType, setConfigType] = useState();
    const {activeStep, StepComponent, stepName, stepTitle} = useMemo(() => {
        const tabsIndex = WIZARD_STEPS.findIndex(getEqual(hash.slice(1), 'stepName'));
        const activeTabIndex = equal(tabsIndex, -1) ? 0 : tabsIndex;

        return WIZARD_STEPS[activeTabIndex];
    }, [hash]);

    useEffect(() => {
        (async () => {
            const {data: {group_launched: groupLaunched}} = await dispatch(requestAppConfiguration({companyAlias: groupAlias, type: 'platform'}));

            setConfigType(groupLaunched ? IMPORT_CONFIG_TYPES.maintenance : IMPORT_CONFIG_TYPES.launch);
        })();
    }, [dispatch, groupAlias]);

    const onChangeActiveStep = stepName => navigate(`${search}#${stepName}`);

    return (
        <React.Fragment>
            <Row>
                <Column xs>
                    <Heading type={HEADING_TYPES['5']} className='mb-5'>{groupName}</Heading>
                    <Heading type={HEADING_TYPES['1']} className='mb-5'>{groupAlias}</Heading>
                </Column>
                <Column>
                    <Sticker className='mr-5' type={STICKER_TYPES.default}>🛠️ Maintenance</Sticker>
                </Column>
            </Row>

            <Text className='mt-20' type={TEXT_TYPES.bodyBold}>Step {activeStep + 1} of {WIZARD_STEPS.length}: <Text isInline>{stepTitle}</Text></Text>
            <ProgressBar className='mt-5' stepsCount={WIZARD_STEPS.length} activeStep={activeStep}/>

            <StepComponent key={stepName} configType={configType} onChangeActiveStep={onChangeActiveStep}/>
        </React.Fragment>
    );
};

export {TPAAutoimportGroup as TestableTPAAutoimportGroup};
export default TPAAutoimportGroup;

import React from 'react';
import {Navigate, useParams} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import PageHeader from '../../../Components/shared/PageHeader';
import ReportingPagesTabs from '../../../Components/Reporting/ReportingPagesTabs';
import BenefitsUsageReporting from '../../../Components/Reporting/BenefitsUsage';
import {ROUTES} from '../../../constants';

const BenefitsUsage = () => {
    const {type} = useParams();

    return (
        <React.Fragment>
            {!type && <Navigate to={`${ROUTES.benefitsUsageReporting}/service_utilization`}/>}

            {type && (
                <React.Fragment>
                    <Row>
                        <Column sm>
                            <PageHeader title='Data Insights Dashboard'/>
                        </Column>
                    </Row>

                    <ReportingPagesTabs/>

                    <BenefitsUsageReporting/>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export {BenefitsUsage as TestableBenefitsUsage};
export default React.memo(BenefitsUsage);

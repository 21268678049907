import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Row from '@frontend/ui-kit/Components/Row';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import Select from '@frontend/ui-kit/Components/Select';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import {Form, Field} from '../../shared/FormComponents';
import StickyActionSection from '../../shared/StickyActionSection';
import {
    requestTimelineTemplate,
    requestTimelineTemplateDeleting,
    requestTimelineTemplateUpdating
} from '../../../actions/adminPortal';
import withPopup from '../../../HOC/withPopup';
import {FORMS, ROUTES, TIMELINE_TEMPLATE_MILESTONES} from '../../../constants';
import {TIMELINE_TEMPLATE_TYPES_OPTIONS} from '../../../options';
import Milestones from '../TimelineTemplatesMilestones';
import './index.scss';

const POPUP_ID = 'timelineTemplateDeletionPopup';

const getFormattedValues = ({id, type, launch_date, ...milestones}) => {
    const formattedMilestones = Object.entries(milestones).map(([key, value]) => ({type: key, due_date: value}));

    return {id, type, launch_date, steps: formattedMilestones};
};

const getFormattedResponse = ({status, steps, ...rest}) => {
    const formattedMilestones = steps.reduce((acc, {type, due_date}) => ({...acc, [type]: due_date}), {});

    return {...rest, ...formattedMilestones};
};

const TimelineTemplatesDetailsForm = ({openPopup, closePopup}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id: templateId} = useParams();
    const [initialValues, setInitialValues] = useState({});

    const redirectToTemplatesList = useCallback(() => navigate(ROUTES.timelineTemplates), []);

    const onDeleteTemplate = form => {
        const onDelete = async () => {
            closePopup();
            form.reset();
            await dispatch(requestTimelineTemplateDeleting(templateId));
            redirectToTemplatesList();
        };

        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button data-testid='delete-template-button' type={BUTTON_TYPES.destructive} onClick={onDelete}>Delete</Button>
            </React.Fragment>
        );
        const popupContent = <Text className='template-details__deletion-popup'>Deleting this template means clients will not be able to select this launch date going forward until a new timeline template is generated for this launch date.</Text>;

        const popupProps = {title: 'Are you sure you want to delete this template?', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestTimelineTemplate(templateId));
            setInitialValues(getFormattedResponse(data));
        })();
    }, [dispatch, templateId]);

    const onSubmit = useCallback(async values => {
        const timelineTemplate = getFormattedValues(values);
        const {isSuccess, submissionErrors, data} = await dispatch(requestTimelineTemplateUpdating(timelineTemplate));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(getFormattedResponse(data));
    }, [dispatch]);

    const showWarning = handleSubmit => {
        const onConfirm = () => {
            closePopup();
            handleSubmit();
        };

        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button data-testid='update-button' type={BUTTON_TYPES.primary} onClick={onConfirm}>Update</Button>
            </React.Fragment>
        );
        const popupContent = <Text>Changing the due date(s) on this template will impact clients' timelines that choose this launch date going forward.</Text>;

        const popupProps = {title: 'Are you sure you want to change the due date(s)?', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    return (
        <Form name={FORMS.createTimelineCalendar} initialValues={initialValues} onSubmit={onSubmit} onSubmitSuccess={redirectToTemplatesList}>
            {({handleSubmit, values, dirty, form}) => {
                const {launch_date: launchDate} = values;
                const milestones = TIMELINE_TEMPLATE_MILESTONES[values.type] || [];
                const handleDeleteTemplate = () => onDeleteTemplate(form);
                const handleSubmitWithWarning = () => dirty ? showWarning(handleSubmit) : handleSubmit();
                const isMilestonesEditDisabled = new Date() > new Date(launchDate);
                const actionBar = (
                    <div className='action-bar'>
                        <div className='action-bar__group'>
                            <Button data-testid='cancel-button' type={BUTTON_TYPES.secondary} onClick={redirectToTemplatesList}>Cancel</Button>
                            <Button data-testid='delete-button' type={BUTTON_TYPES.destructive} onClick={handleDeleteTemplate}>Delete</Button>
                        </div>
                    </div>
                );

                return (
                    <form onSubmit={handleSubmit} className='template-details' noValidate>
                        <Row>
                            <Column sm>
                                <ContentSection className='template-creation__general-information'>
                                    <Heading className='template-details__title'>General Information</Heading>

                                    <Row>
                                        <Column sm={6}>
                                            <Field name='type' disabled>
                                                {props => <Select {...props} options={TIMELINE_TEMPLATE_TYPES_OPTIONS} label='Template Type' isRequired/>}
                                            </Field>
                                        </Column>
                                        <Column sm={6}>
                                            <Field name='launch_date' disabled>
                                                {props => <DatePicker {...props} label='Launch Date' isRequired/>}
                                            </Field>
                                        </Column>
                                    </Row>
                                </ContentSection>

                                <Milestones milestones={milestones} isEditDisabled={isMilestonesEditDisabled} launchDate={launchDate}/>
                            </Column>

                            <Column constant>
                                <StickyActionSection title='Edit Custom Template' actionBar={actionBar}>
                                    <Text>This template can only be edited up until the template's start date.</Text>

                                    {!isMilestonesEditDisabled && <Button className='save-button' data-testid='save-button' onClick={handleSubmitWithWarning} type={BUTTON_TYPES.primary}>Save</Button>}
                                </StickyActionSection>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

TimelineTemplatesDetailsForm.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {TimelineTemplatesDetailsForm as TestableTimelineTemplatesDetailsForm};
export default withPopup(POPUP_ID)(TimelineTemplatesDetailsForm);

import React, {useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Layout from '../Layout';
import LookerDashboard from '../LookerDashboard';
import DownloadReportButton from '../DownloadReportButton';
import {getActiveCompany} from '../../../selectors/general';
import {LOOKER_URL, LOOKER_URLS} from '../../../constants';

const DASHBOARD_ID = {
    [LOOKER_URLS.dev]: 49,
    [LOOKER_URLS.stage]: 9,
    [LOOKER_URLS.prod]: 10
}[LOOKER_URL];
const DEMO_DASHBOARD_ID = 'demo_embedded_dashboard::demo_book_of_business';

const BookOfBusiness = () => {
    const [filters, setFilters] = useState(null);
    const {is_demo: isDemoCompany} = useSelector(getActiveCompany);
    const dashboardId = useMemo(() => isDemoCompany ? DEMO_DASHBOARD_ID : DASHBOARD_ID, [isDemoCompany]);

    return (
        <Layout>
            <div className='reporting'>
                <Row between='sm'>
                    <Column sm={9}>
                        <Heading className='section-heading' type={HEADING_TYPES['3']}>
                            Book of Business
                        </Heading>
                        <Text className='mt-5 mb-4'>
                            View a summary of data insights across your HealthJoy client portfolio.
                            Clients that you've been assigned to in the dashboard will all appear in your summary.
                            If you do not see a client included in your summary, please contact your HealthJoy representative.
                        </Text>
                        <Text className='reporting-info-disclaimer mb-20' type={TEXT_TYPES.helper}>
                            Disclaimer: this information may be limited due to HIPAA requirements to properly de-identify data in aggregated format.
                        </Text>
                    </Column>
                    <Column sm constant>
                        <DownloadReportButton dashboardId={dashboardId} filters={filters}/>
                    </Column>
                </Row>

                <LookerDashboard dashboardId={dashboardId} onUpdateFilters={setFilters}/>
            </div>
        </Layout>
    );
};

export {BookOfBusiness as TestableBookOfBusiness};
export default React.memo(BookOfBusiness);

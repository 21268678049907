import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Wizard from '../../../Containers/shared/Wizard';
import AudienceStep from '../BroadcastWizardSteps/AudienceStep';
import ContentStep from '../BroadcastWizardSteps/ContentStep';
import InformationStep from '../BroadcastWizardSteps/InformationStep';
import {clearBroadcast, setBroadcastTemplate} from '../../../actions/broadcaster';
import {requestFeatureFlag} from '../../../actions/general';
import {FEATURE_FLAGS} from '../../../constants';

const BASE_WIZARD_STEPS = [
    {StepComponent: ContentStep, stepName: 'content', stepTitle: 'Broadcast Content'},
    {StepComponent: InformationStep, stepName: 'information', stepTitle: 'Schedule'}
];

const ADVANCED_AUDIENCE_STEPS = [
    {StepComponent: AudienceStep, stepName: 'audience', stepTitle: 'Select Audience'},
    ...BASE_WIZARD_STEPS
];

const BroadcastWizard = () => {
    const dispatch = useDispatch();
    const [isAdvanced, setIsAdvanced] = useState(false);

    useEffect(() => {
        (async () => {
            const {flag: isBroadcastSegmentationEnabled} = await dispatch(requestFeatureFlag(FEATURE_FLAGS.broadcastSegmentationEnabled));
            setIsAdvanced(isBroadcastSegmentationEnabled);
        })();
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearBroadcast());
            dispatch(setBroadcastTemplate(null));
        };
    }, [dispatch]);

    const wizardSteps = isAdvanced ? ADVANCED_AUDIENCE_STEPS : BASE_WIZARD_STEPS;

    return <Wizard steps={wizardSteps}/>;
};

export {BroadcastWizard as TestableBroadcastWizard};
export default BroadcastWizard;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, Outlet} from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import Header from '../../../Containers/shared/Header';
import Loader from '../../../Containers/shared/Loader';
import Toast from '../Toast';
import PageLayout from '../../../Containers/shared/PageLayout';
import Sidebar from '../../../Containers/shared/Sidebar';
import withNavigationControl from '../../../HOC/withNavigationControl';
import withWorkspaceControl from '../../../HOC/withWorkspaceControl';
import withAuthorizationControl from '../../../HOC/withAuthorizationControl';
import withRedirectionStrategy from '../../../HOC/withRedirectionStrategy';
import {requestAdminPortalBasicData} from '../../../actions/general';
import {clearMultipleChoice} from '../../../actions/adminPortal';
import {getProfileInfo} from '../../../selectors/general';
import usePrevious from '../../../hooks/usePrevious';
import {WORKSPACES} from '../../../constants';
import {compose, equal} from '../../../utils';

const AdminPortalLayout = props => {
    const dispatch = useDispatch();
    const {id: profileInfoId} = useSelector(getProfileInfo);
    const {pathname} = useLocation();
    const isLoaded = !!profileInfoId;
    const sectionPathName = pathname.split('/')[2];
    const prevSectionPathName = usePrevious(sectionPathName);

    useEffect(() => {
        dispatch(requestAdminPortalBasicData());

        return () => {
            dispatch(clearMultipleChoice());
        };
    }, [dispatch]);

    useEffect(() => {
        if (prevSectionPathName && !equal(sectionPathName, prevSectionPathName)) {
            dispatch(clearMultipleChoice());
        }
    }, [sectionPathName, dispatch]);

    return (
        <React.Fragment>
            <Loader/>
            <Toast/>

            {isLoaded && (
                <React.Fragment>
                    <Header/>

                    <MainWrapper>
                        <Sidebar/>

                        <PageLayout>
                            {/* FYI: key prop helps to remount component after each relocation */}
                            <Outlet key={pathname} {...props}/>
                        </PageLayout>
                    </MainWrapper>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export {AdminPortalLayout as testableAdminPortalLayout};
export default compose(
    withAuthorizationControl({isAuthorizationPage: false}),
    withRedirectionStrategy,
    withNavigationControl,
    withWorkspaceControl({workspace: WORKSPACES.adminPortal})
)(AdminPortalLayout);

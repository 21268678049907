import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams, Link as RouterLink, useNavigate} from 'react-router-dom';
import MultiSelectInput from '@frontend/ui-kit/Components/MultiSelectInput';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Separator from '@frontend/ui-kit/Components/Separator';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import PlanVersionHistoryPopup from '../PlanVersionHistoryPopup';
import CarrierAutocomplete from '../../shared/CarrierAutocomplete';
import CongratulationPopup from '../../shared/CongratulationPopup';
import CustomizablePopup from '../../shared/CustomizablePopup';
import RevisionsField from '../../shared/RevisionsField';
import {Form, Field, FieldArray} from '../../shared/FormComponents';
import StickyActionSection from '../../shared/StickyActionSection';
import PlanBenefitsCustomization from './PlanBenefitsCustomization';
import FindCareNetworksSettings, {getCorrectNetworksFormattedData, getCorrectNetworksFormData} from './FindCareNetworksSettings';
import withPopup from '../../../HOC/withPopup';
import usePrevious from '../../../hooks/usePrevious';
import {
    requestInsuranceNetworks,
    requestPlanManagerBenefitsCategories,
    requestPlanManagerPlan,
    requestPlanManagerPlanUpdating
} from '../../../actions/adminPortal';
import {getFormRevisions, getCoreCarriers} from '../../../selectors/shared';
import {CORE_PLAN_TYPES, PLAN_MANAGER_PLAN_STATUSES, DEFAULT_PHONE_PLACEHOLDER, FORMS, ROUTES, LINE_BREAK_CHARACTER} from '../../../constants';
import {MEDICAL_PLAN_TYPE_OPTIONS, PLAN_TYPE_OPTIONS, PLAN_KIND_OPTIONS} from '../../../options';
import {
    compose,
    equal,
    getEqual,
    validateRequired,
    parseQuery,
    isEmpty,
    formatDate,
    trimEnd,
    getTextFromHtml,
    validateMaxLength,
    promisifyAsyncFunction,
    validateCorrectNetworks
} from '../../../utils';
import './index.scss';

const POPUP_ID = 'PlanManagerDetailsPopup';

const SERVICE_VISITS_LINE_BREAKS_FIELDS = ['copay_in_network', 'copay_out_of_network', 'coinsurance_in_network', 'coinsurance_out_of_network'];
const SERVICE_VISITS_WORD_REGEXP = /([A-Z][a-z]+)/g;
const SERVICE_VISITS_GROUP_WORDS_LENGTH = 3;

const MULTI_SELECT_INPUT_PLACEHOLDER = 'Type value and press ENTER to apply changes.';

/* istanbul ignore next */
const validate = values => {
    const {medtool_plan: medtoolPlanValues = {}} = values;

    return ({
        medtool_plan: {
            display_name: validateRequired(medtoolPlanValues.display_name),
            network_name: validateRequired(medtoolPlanValues.network_name),
            plan_effective_date: validateRequired(medtoolPlanValues.plan_effective_date),
            plan_expiration_date: validateRequired(medtoolPlanValues.plan_expiration_date),
            cost_share_variance: {
                tier2_description: validateMaxLength(medtoolPlanValues?.cost_share_variance?.tier2_description, 25),
                tier3_description: validateMaxLength(medtoolPlanValues?.cost_share_variance?.tier3_description, 25),
                after_deductible_disclaimer_for_in_network: validateMaxLength(getTextFromHtml(medtoolPlanValues?.cost_share_variance?.after_deductible_disclaimer_for_in_network), 250),
                after_deductible_disclaimer_for_out_of_network: validateMaxLength(getTextFromHtml(medtoolPlanValues?.cost_share_variance?.after_deductible_disclaimer_for_out_of_network), 250),
                after_deductible_disclaimer_for_preferred_in_network: validateMaxLength(getTextFromHtml(medtoolPlanValues?.cost_share_variance?.after_deductible_disclaimer_for_preferred_in_network), 250)
            },
            correct_networks: validateCorrectNetworks(medtoolPlanValues.correct_networks)
        }
    });
};

// FYI: This func is used for adding line breaks in Service Visits (DEV-7365) (Pasha, 14.02.2021)
// rules for adding line breaks
// 1: The character is a capital letter next to a space (e.g. " C") or starts a group of characters (like " $25") AND There is a dot-space (“. “)  before the character
// 2: The character is a capital letter next to a space (e.g. " C") AND there are at least 3 words* between:
// a) the character and previous line break or
// b) between the character and beginning of the text
// If you need to remove unnecessary line break, add \D in the end of upper line. It implemented in webview project.
const getServiceVisitValueWithLineBreaks = value => {
    return value
        .replace(/(\. )/g, `.${LINE_BREAK_CHARACTER}`)
        .split(SERVICE_VISITS_WORD_REGEXP)
        .reduce((acc, item) => {
            const prevRowString = acc.split(new RegExp(`[;${LINE_BREAK_CHARACTER}]`, 'g')).pop();
            const prevWordsLength = prevRowString.split(/[ /]/g).filter(String).length;
            const isTargetWord = !!item.match(SERVICE_VISITS_WORD_REGEXP);

            if (isTargetWord && prevWordsLength >= SERVICE_VISITS_GROUP_WORDS_LENGTH) {
                return trimEnd(acc) + LINE_BREAK_CHARACTER + item;
            }

            return acc + item;
        }, '');
};

const PlanManagerDetailsForm = ({openPopup, closePopup}) => {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({});
    const coreCarriers = useSelector(getCoreCarriers) || [];
    const formRevisions = useSelector(getFormRevisions);
    const {search} = useLocation();
    const prevSearch = usePrevious(search);
    const {id: planId} = useParams();
    const dispatch = useDispatch();

    const getServiceVisitItems = benefit => Object.entries(benefit).reduce((acc, [key, value]) => {
        const isValueWithLineBreak = SERVICE_VISITS_LINE_BREAKS_FIELDS.some(getEqual(key));

        return {...acc, [key]: (value?.length && isValueWithLineBreak) ? getServiceVisitValueWithLineBreaks(value) : value};
    }, {});

    const getEnhancedServiceVisit = useCallback(({items, ...restValues}) => {
        const enhancedItems = items
            .map(getServiceVisitItems)
            .map(({type, ...restItems}) => ({type: type.toLowerCase(), ...restItems}));

        return ({...restValues, items: enhancedItems});
    }, []);

    const enhancedInitialValues = useMemo(() => {
        const {medtool_plan: medtoolPlan} = initialValues || {};
        const serviceVisits = medtoolPlan?.cost_share_variance?.service_visits || [];

        const updatedServiceVisits = serviceVisits.map(getEnhancedServiceVisit);
        return {
            ...initialValues,
            isFinAccount: !!initialValues?.medtool_plan?.financial_account_type,
            ...initialValues.medtool_plan && {
                medtool_plan: {
                    ...initialValues.medtool_plan,
                    correct_networks: getCorrectNetworksFormData(initialValues?.medtool_plan?.correct_networks),
                    ...initialValues.medtool_plan.cost_share_variance && {
                        cost_share_variance: {
                            ...initialValues.medtool_plan.cost_share_variance,
                            service_visits: updatedServiceVisits
                        }
                    },
                    issuer: medtoolPlan.issuer_info ? {name: medtoolPlan.issuer_info.issuer_name, hios_id: medtoolPlan.issuer_info.hios_id} : null
                }
            }
        };
    }, [initialValues, getEnhancedServiceVisit]);

    useEffect(() => {
        dispatch(requestPlanManagerBenefitsCategories());
    }, [dispatch]);

    const onGoToPlanList = useCallback(() => navigate(ROUTES.planManager), []);

    const onRequestFormData = useCallback(async () => {
        const {version_id: versionId, comparison_versions_ids: comparisonVersionsIds} = parseQuery(search);

        const {data: plan, isSuccess} = await dispatch(requestPlanManagerPlan(planId, versionId, comparisonVersionsIds));

        if (isSuccess) {
            setInitialValues(plan);

            return false;
        }

        onGoToPlanList();
    }, [dispatch, onGoToPlanList, planId, search]);

    useEffect(() => {
        if (!equal(search, prevSearch)) {
            onRequestFormData();
        }
    }, [search, prevSearch, onRequestFormData]);

    const onOpenCongratulationPopup = () => {
        const actionBar = (
            <React.Fragment>
                <Button className='plan-manager-details-save-popup__button' type={BUTTON_TYPES.secondary} onClick={onGoToPlanList}>Go to Plan List</Button>
                <Button className='plan-manager-details-save-popup__button' type={BUTTON_TYPES.primary} onClick={closePopup}>OKAY</Button>
            </React.Fragment>
        );

        const children = (
            <CongratulationPopup className='plan-manager-details-save-popup' actionBar={actionBar}>
                <Heading type={HEADING_TYPES['4']}>You've successfully saved a new version of this plan.</Heading>
            </CongratulationPopup>
        );

        return openPopup({type: POPUP_TYPES.fullscreen, children});
    };

    const onSaveAsNewVersion = async values => {
        const {version_id: versionId} = parseQuery(search);

        const {data: plan, isSuccess, submissionErrors} = await dispatch(requestPlanManagerPlanUpdating(planId, versionId, values));

        if (!isSuccess) {
            return submissionErrors;
        }

        onOpenCongratulationPopup();
        setInitialValues(plan);
    };

    const onOpenUnchangedFormPopup = values => {
        const onSave = () => {
            closePopup();
            onSaveAsNewVersion(values);
        };

        const content = 'By saving this plan as-is, you will be reverting back to an older version. If you need to make updates, please do so before saving this plan.';
        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button type={BUTTON_TYPES.primary} onClick={onSave}>Save as new version</Button>
            </React.Fragment>
        );

        const popupProps = {title: 'Are you sure you want to restore this version without making any new changes?', content, actionBar};
        const children = <CustomizablePopup {...popupProps}/>;

        return openPopup({type: POPUP_TYPES.simple, children});
    };

    const onSubmit = (values, form) => {
        const {dirty: isDirtyForm} = form.getState();
        const formattedValues = {
            ...values,
            medtool_plan: {
                ...values.medtool_plan,
                correct_networks: getCorrectNetworksFormattedData(values.medtool_plan.correct_networks),
                issuer: undefined
            }
        };

        return isDirtyForm ? onSaveAsNewVersion(formattedValues) : onOpenUnchangedFormPopup(formattedValues);
    };

    const onOpenPlanVersionHistoryPopup = () => {
        const children = <PlanVersionHistoryPopup onClose={closePopup} planId={planId}/>;

        openPopup({type: POPUP_TYPES.right, children});
    };

    const onIssuerChange = (value, form) => {
        const {issuer_name: issuerName, hios_id: hiosId, id} = coreCarriers.find(getEqual(value.hios_id, 'hios_id')) || {};

        form.change('medtool_plan.issuer_info.issuer_name', issuerName);
        form.change('medtool_plan.issuer_info.hios_id', hiosId);
        form.change('medtool_plan.issuer_info.id', id);
    };

    const getFileField = field => {
        const {name, value} = field;

        return (
            <div key={name} className='file-field'>
                <Input label='Sample ID Files' {...field} onIconClick={() => window.open(value, '_blank')} icon={ICON_TYPES.externalLink}/>
            </div>
        );
    };

    const getFile = file => <RevisionsField key={file} name={file}>{getFileField}</RevisionsField>;

    const getFiles = ({fields = []}) => fields.length ? fields.map(getFile) : <Input label='Sample ID Files' placeholder='No files' disabled/>;

    const getVersionInfo = (version, index, versions) => {
        const {id, history_changed_by: changedBy, history_changed_at: changedAt, history_version: versionNumber} = version?.meta || {};
        const formattedChangedAt = formatDate(changedAt, 'MM/dd/yyyy');
        const isLastVersion = equal(index + 1, versions.length);

        return (
            <Row className='version-info' key={id}>
                <Column sm={3}>
                    <Text isInline type={TEXT_TYPES.helperBold} className='version-info__version-number'>v.{versionNumber}</Text>
                </Column>
                <Column sm={9}>
                    <Text type={TEXT_TYPES.helper} className='version-info__author'>{changedBy}</Text>
                    <Text type={TEXT_TYPES.helper} className='version-info__date'>{formattedChangedAt}</Text>
                </Column>

                {!isLastVersion && <Separator className='version-info__separator'/>}
            </Row>
        );
    };

    const loadPharmacyBenefitManagerOptions = useCallback(promisifyAsyncFunction(async query => {
        const {data} = await dispatch(requestInsuranceNetworks({query}));

        return data.map(({name}) => ({label: name, value: name}));
    }), [dispatch]);

    return (
        <Form name={FORMS.planManagerDetails} validate={validate} onSubmit={onSubmit} initialValues={enhancedInitialValues}>
            {({handleSubmit, values, form}) => {
                const {isFinAccount, medtool_plan: medtoolPlan, core_plan: corePlan, meta} = values;
                const {active: isActive} = corePlan || {};
                const {
                    name: planName,
                    plan_effective_date: planEffectiveDate,
                    plan_expiration_date: planExpirationDate,
                    plan_kind: planType,
                    sbc_url: sbcUrl,
                    issuer_info: issuerInfo
                } = medtoolPlan || {};
                const {history_version: versionNumber, history_status: status} = meta || {};
                const [formattedPlanEffectiveDate, formattedPlanExpirationDate] = [planEffectiveDate, planExpirationDate].map(date => new Date(date));
                const isMedicalPlan = equal(planType, CORE_PLAN_TYPES.medical);

                const {originalRevision, comparisonRevisions = []} = formRevisions || {};
                const planVersions = [originalRevision, ...comparisonRevisions];
                const isCompareVersions = !isEmpty(comparisonRevisions);
                const isLastMerged = equal(status, PLAN_MANAGER_PLAN_STATUSES.lastMerged);
                const {version_id: versionId} = parseQuery(search);
                // FYI: Plan should be deactivated only for current plan, not for plan version (Pasha, 16.11.2021)
                const isDeactivatedPlan = !isActive && !versionId;
                const planVersionClassName = `action-bar-header__plan-version ${isDeactivatedPlan ? 'action-bar-header__plan-version_deactivated' : ''}`;

                const carrierDescription = `${issuerInfo?.issuer_name}${issuerInfo?.hios_id ? `, HIOS ID: ${issuerInfo.hios_id}` : ''}`;
                const CarrierLink = <RouterLink to={`${ROUTES.carrierManager}/${issuerInfo?.id}`} target='_blank'>{carrierDescription}</RouterLink>;
                const carrierAutocompleteProps = {
                    label: 'Carrier',
                    isCore: true,
                    isLabelWithHiosId: true,
                    isCreatable: false,
                    name: 'medtool_plan.issuer',
                    onChange: onIssuerChange,
                    formatValue: ({name, hios_id}) => ({label: name, value: hios_id}),
                    parseValue: ({label, value}) => ({name: label, hios_id: value}),
                    filterOption: () => true,
                    ...issuerInfo && {description: issuerInfo.id ? CarrierLink : carrierDescription}
                };

                const resetFinAccountType = () => form.change('medtool_plan.financial_account_type', null);

                const actionBar = (
                    <div className='action-bar__buttons'>
                        <div className='action-bar__buttons-group'>
                            <Button type={BUTTON_TYPES.secondary} onClick={onGoToPlanList}>Close</Button>
                            <Button type={BUTTON_TYPES.secondary} onClick={onOpenPlanVersionHistoryPopup}>Plan Version History</Button>
                        </div>
                    </div>
                );

                const actionBarTitle = (
                    <React.Fragment>
                        <Heading className='action-bar-header__title' type={HEADING_TYPES['4']}>
                            {isCompareVersions && 'You Compare versions - '}
                            {!isCompareVersions && versionNumber && (
                                <Text className={planVersionClassName} isInline>
                                    v{versionNumber}
                                </Text>
                            )}
                            {planName}
                        </Heading>
                        {isLastMerged && !isCompareVersions && !isDeactivatedPlan && <Sticker type={STICKER_TYPES.primary} className='action-bar-header__sticker'>Current</Sticker>}
                        {isDeactivatedPlan && <Sticker type={STICKER_TYPES.default} className='action-bar-header__sticker'>Deactivated</Sticker>}
                    </React.Fragment>
                );

                return (
                    <form>
                        <Row className='plan-manager-details'>
                            <Field name='company_alias'>{props => <Input {...props} type='hidden'/>}</Field>

                            <Column sm>
                                <ContentSection className='details-section'>
                                    <Heading className='details-section__title'>General Information</Heading>

                                    <Row>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.display_name'>
                                                {props => <Input {...props} label='SFTP Name (Display Name)' isRequired/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.display_names'>
                                                {props => <MultiSelectInput {...props} description='Type value and press ENTER to apply changes. Repeat to add multiple values.' placeholder={MULTI_SELECT_INPUT_PLACEHOLDER} label='Additional SFTP Names'/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <CarrierAutocomplete {...carrierAutocompleteProps}/>
                                            <Field name='medtool_plan.issuer_info.issuer_name'>
                                                {props => <Input {...props} type='hidden'/>}
                                            </Field>
                                            <Field name='medtool_plan.issuer_info.hios_id'>
                                                {props => <Input {...props} type='hidden'/>}
                                            </Field>
                                            <Field name='medtool_plan.issuer_info.id'>
                                                {props => <Input {...props} type='hidden'/>}
                                            </Field>
                                        </Column>
                                    </Row>

                                    <Separator/>

                                    <Row>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.plan_id'>
                                                {props => <Input {...props} disabled placeholder='Plan ID' label='Plan ID'/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.name'>
                                                {props => <Input {...props} disabled placeholder='Plan Name' label='Plan Name'/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.plan_kind'>
                                                {props => <Select {...props} options={PLAN_KIND_OPTIONS} placeholder='Plan Category' label='Plan Category'/>}
                                            </RevisionsField>
                                        </Column>
                                    </Row>

                                    <Separator/>

                                    <Row>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.type'>
                                                {props => <Select {...props} options={isMedicalPlan ? MEDICAL_PLAN_TYPE_OPTIONS : PLAN_TYPE_OPTIONS} placeholder='Plan Type' label='Plan Type'/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.plan_effective_date'>
                                                {props => <DatePicker {...props} {...planExpirationDate && {maxDate: formattedPlanExpirationDate}} placeholder='Choose date...' label='Plan Effective Date' isRequired/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.plan_expiration_date'>
                                                {props => <DatePicker {...props} {...planEffectiveDate && {minDate: formattedPlanEffectiveDate}} placeholder='Choose date...' label='Plan Expiration Date' isRequired/>}
                                            </RevisionsField>
                                        </Column>
                                    </Row>

                                    {isMedicalPlan && (
                                        <React.Fragment>
                                            <Separator/>

                                            <Row>
                                                <Column sm={4}>
                                                    <RevisionsField name='medtool_plan.cost_share_variance.number_of_deductible_tiers'>
                                                        {props => <Input {...props} placeholder='No data' disabled label='Number of Deductible Tiers'/>}
                                                    </RevisionsField>
                                                </Column>
                                                <Column sm={4}>
                                                    <RevisionsField name='medtool_plan.cost_share_variance.number_of_moop_tiers'>
                                                        {props => <Input {...props} placeholder='No data' disabled label='Number of MOOP Tiers'/>}
                                                    </RevisionsField>
                                                </Column>
                                                <Column sm={4}>
                                                    <RevisionsField name='medtool_plan.cost_share_variance.number_of_network_tiers'>
                                                        {props => <Input {...props} placeholder='No data' disabled label='Number of Network Tiers'/>}
                                                    </RevisionsField>
                                                </Column>
                                            </Row>
                                        </React.Fragment>
                                    )}
                                </ContentSection>

                                <ContentSection className='details-section'>
                                    <Heading className='details-section__title'>Supporting Information</Heading>

                                    <Row>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.network_name'>
                                                {props => <Input {...props} placeholder='Display Name' label='Provider Network Name' isRequired/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.network_url'>
                                                {props => <Input {...props} placeholder='Display Name' label='Network URL'/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.network_url_alias'>
                                                {props => <Input {...props} placeholder='Display Name' label='Network URL Alias'/>}
                                            </RevisionsField>
                                        </Column>
                                    </Row>

                                    <Separator/>

                                    <Row>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.member_support_phone'>
                                                {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Network Phone Number'/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.secondary_network_name'>
                                                {props => <Input {...props} placeholder='Display Name' label='Secondary Network Name'/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.secondary_network_url'>
                                                {props => <Input {...props} placeholder='Display Name' label='Secondary Network URL'/>}
                                            </RevisionsField>
                                        </Column>
                                    </Row>

                                    <Separator/>

                                    <Row>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.secondary_network_url_alias'>
                                                {props => <Input {...props} placeholder='Display Name' label='Secondary Network URL Alias'/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.secondary_network_support_phone'>
                                                {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Secondary Network Support Phone'/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.show_only_in_network_data'>
                                                {props => <Checkbox {...props} className='mt-13' caption='Show Only In Network Data' description='If checked, the HealthJoy app and CRM will only display in network information.'/>}
                                            </RevisionsField>
                                        </Column>
                                    </Row>

                                    <Separator/>

                                    <FindCareNetworksSettings/>
                                </ContentSection>

                                <ContentSection className='details-section'>
                                    <Heading className='details-section__title'>Financial Accounts</Heading>
                                    <Row>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.fsa_eligible'>
                                                {props => <Checkbox {...props} caption='FSA Eligible' disabled/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.hra_eligible'>
                                                {props => <Checkbox {...props} caption='HRA Eligible' disabled/>}
                                            </RevisionsField>
                                        </Column>
                                        <Column sm={4}>
                                            <RevisionsField name='medtool_plan.hsa_eligible'>
                                                {props => <Checkbox {...props} caption='HSA Eligible' disabled/>}
                                            </RevisionsField>
                                        </Column>
                                    </Row>
                                </ContentSection>

                                {isMedicalPlan && (
                                    <ContentSection className='details-section'>
                                        <Text className='details-section__title' type={TEXT_TYPES.bodyBold}>Plan Files and Other Information</Text>

                                        <Row>
                                            <Column sm={4}>
                                                <div className='file-field'>
                                                    <RevisionsField name='medtool_plan.sbc_url'>
                                                        {props => {
                                                            const sbcFileProps = {
                                                                placeholder: 'No file',
                                                                label: 'SBC File',
                                                                ...(sbcUrl && isEmpty(formRevisions)) && {
                                                                    icon: ICON_TYPES.externalLink,
                                                                    onIconClick: () => window.open(sbcUrl, '_blank')
                                                                }
                                                            };

                                                            return <Input {...props} {...sbcFileProps}/>;
                                                        }}
                                                    </RevisionsField>
                                                </div>
                                            </Column>
                                            <Column sm={4}>
                                                <FieldArray name='medtool_plan.sample_id_card_urls'>{getFiles}</FieldArray>
                                            </Column>
                                            <Column sm={4}>
                                                <RevisionsField name='medtool_plan.preauthorization_phone'>
                                                    {props => <Input {...props} type='tel' placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Preauthorization Phone'/>}
                                                </RevisionsField>
                                            </Column>
                                        </Row>

                                        <Separator/>

                                        <Row>
                                            <Column sm={4}>
                                                <RevisionsField name='medtool_plan.is_pathways_redirect_enabled'>
                                                    {props => <Checkbox {...props} caption='Redirect to Pathways'/>}
                                                </RevisionsField>
                                            </Column>
                                            <Column sm={4}>
                                                <RevisionsField name='medtool_plan.is_bariatric_covered'>
                                                    {props => <Checkbox {...props} caption='SBC Bariatric status (for EDH)'/>}
                                                </RevisionsField>
                                            </Column>
                                        </Row>

                                    </ContentSection>
                                )}

                                {!isCompareVersions && isMedicalPlan && <PlanBenefitsCustomization/>}
                            </Column>

                            <Column constant>
                                <StickyActionSection className='action-bar' title={actionBarTitle} actionBar={actionBar}>
                                    {isCompareVersions ? (
                                        <div className='plan-versions'>{planVersions.map(getVersionInfo)}</div>
                                    ) : (
                                        <React.Fragment>
                                            <Text>
                                                Use the fields on this page to modify this plan. Once you are finished, save your
                                                changes and the revision will be sent to HealthJoy's implementation team for
                                                reprocessing.
                                            </Text>
                                            <Button className='save-button' onClick={handleSubmit} type={BUTTON_TYPES.primary}>Save as new version</Button>

                                        </React.Fragment>
                                    )}
                                </StickyActionSection>
                            </Column>
                        </Row>
                    </form>
                );
            }}
        </Form>
    );
};

PlanManagerDetailsForm.propTypes = {
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

export {PlanManagerDetailsForm as TestablePlanManagerDetailsForm, getServiceVisitValueWithLineBreaks};
export default compose(
    withPopup(POPUP_ID),
    React.memo
)(PlanManagerDetailsForm);

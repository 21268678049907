import traitsMiddleware from './traitsMiddleware';
import {ANALYTICS_EVENT_NAMES, ANALYTICS_PLATFORM, TRACKED_ROUTES} from './constants';
import {getFileFormat, equal, toCapitalize} from '../utils';
import {GENERAL_ROLES, LOCAL_STORAGE_ITEMS, ROUTES} from '../constants';

class AnalyticsService {
    get analytics() {
        return window.analytics;
    }

    get userTraits() {
        if (!this.analytics.user) {
            return false;
        }

        return this.analytics.user().traits();
    }

    load = key => {
        this.analytics.addSourceMiddleware(traitsMiddleware);
        this.analytics.load(key);
        this.analytics.page(); // FYI: Because some destinations require a page call to instantiate their libraries, we must call page at least once per page load (Vlad, 05.07.20)
    };

    identify = (userId, traits, options, callback) => {
        this.analytics.identify(userId, traits, options, callback);
        localStorage.setItem(LOCAL_STORAGE_ITEMS.isAnalyticsIdentified, true);
        localStorage.setItem(LOCAL_STORAGE_ITEMS.userTraits, JSON.stringify(traits)); // HACK: On the first PageLoad event userTraits are not available inside window.analytics, so we're getting them from the localStorage (Vlad, 11.01.21)
    };

    reset = () => {
        this.analytics.reset();
        localStorage.setItem(LOCAL_STORAGE_ITEMS.isAnalyticsIdentified, false);
        localStorage.removeItem(LOCAL_STORAGE_ITEMS.userTraits);
    };

    generateBasicTraits = ({alias, generalRole, userRole, userType, is2faEnabled}) => {
        return {
            user_campaign: alias,
            is_2fa_enabled: is2faEnabled,
            is_internal: equal(generalRole, GENERAL_ROLES.admin),
            ...(userType && {user_type: userType}),
            ...(userRole && {user_role: userRole})
        };
    };

    track = (eventName, properties, options, callback) => {
        const {pathname} = window.location;
        const eventSourcePathname = equal(pathname, ROUTES.root) ? ROUTES.login : pathname; // FYI: We're assuming that the root route is login because the root route is used only for login and redirections. (5.01.2021, Pasha)
        const isTrackedRoute = TRACKED_ROUTES.some(route => eventSourcePathname.startsWith(route));

        if (!isTrackedRoute) {
            return false;
        }

        // FYI: we filter out all dynamic numbers (id, plan_period, etc.) in order to get last part of path. Be careful with new principles of routes generation and try not to break the logic described below.
        // For example: /education_and_marketing/348 => education_and_marketing, /benefits/150204/core_benefits_details => core_benefits_details (22.04.2024, Oleh);
        const eventLocation = eventSourcePathname.split('/').filter(item => !Number(item)).pop();
        const formattedEventLocation = eventLocation.split('_').map(toCapitalize).join('');

        this.analytics?.track(`${ANALYTICS_PLATFORM}.${formattedEventLocation}.${eventName}`, properties, options, callback);
    };

    trackPageLoad = isInitialLoad => this.track(ANALYTICS_EVENT_NAMES.pageLoad, {initial_load: isInitialLoad});

    trackDownload = ({name, generationDate, timeFrame}) => {
        const properties = {
            download_filename: name,
            download_format: getFileFormat(name),

            ...(generationDate && {report_generation_date: generationDate}),
            ...(timeFrame && {report_time_frame: timeFrame})
        };

        this.track(ANALYTICS_EVENT_NAMES.download, properties);
    };

    trackToggleSelection = (selectName, selectValue) => this.track(ANALYTICS_EVENT_NAMES.toggleSelection, {select_name: selectName, select_value: selectValue});

    trackSearch = (searchName, searchValue) => this.track(ANALYTICS_EVENT_NAMES.search, {search_name: searchName, search_term: searchValue});

    trackExternalLink = (linkName, linkUrl) => this.track(ANALYTICS_EVENT_NAMES.externalLink, {link_name: linkName, link_url: linkUrl});

    trackClicked = (button, params) => this.track(ANALYTICS_EVENT_NAMES.clicked, {button, ...params});
}

export default new AnalyticsService();

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import {ROUTES} from '../../../../constants';
import {requestGroupAutomationStatus} from '../../../../actions/adminPortal';
import {convertDateToTimeZone, formatDate} from '../../../../utils';

const AutomationStatusDetailsPopup = ({automationStatus}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {group_alias: groupAlias} = useParams();
    const [groupAutomationData, setGroupAutomationData] = useState({});

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestGroupAutomationStatus(groupAlias));

            setGroupAutomationData(data);
        })();
    }, []);

    const onViewSFTPConfig = () => navigate(`${ROUTES.appConfiguration}/sftp/${groupAlias}`);

    const formatDateToTimeZone = date => `${formatDate(convertDateToTimeZone(date), 'MM/dd/yyyy H:mm')} CST`;

    return (
        <PopupContent title='Automation Status Details'>
            <div className='pb-20'>
                <Row rowGap='lg' middle='xs'>
                    <Column sm={4}>
                        <Text type={TEXT_TYPES.helper}>Automation Status</Text>
                    </Column>
                    <Column sm={8}>
                        <Sticker type={STICKER_TYPES.default} className='mr-10'>{automationStatus}</Sticker>
                        <Button data-testid='sftp-config-button' onClick={onViewSFTPConfig} type={BUTTON_TYPES.tertiary}>View SFTP Configurations</Button>
                    </Column>

                    <Column sm={4}>
                        <Text type={TEXT_TYPES.helper}>SFTP Last Updated</Text>
                    </Column>
                    <Column sm={8}>
                        <Text>{groupAutomationData?.sftp_last_updated_dt ? formatDateToTimeZone(groupAutomationData?.sftp_last_updated_dt) : 'Not Updated'}</Text>
                        {groupAutomationData?.sftp_last_updated_file && <Text>&#123;{groupAutomationData?.sftp_last_updated_file}&#125;</Text>}
                    </Column>

                    <Column sm={4}>
                        <Text type={TEXT_TYPES.helper}>SFTP Data Received to Auto-import</Text>
                    </Column>
                    <Column sm={8}>
                        <Text>{groupAutomationData?.sftp_data_received_dt ? formatDateToTimeZone(groupAutomationData?.sftp_data_received_dt) : 'Not Updated'}</Text>
                        {groupAutomationData?.sftp_data_received_file && <Text>&#123;{groupAutomationData?.sftp_data_received_file}&#125;</Text>}
                    </Column>

                    <Column sm={4}>
                        <Text type={TEXT_TYPES.helper}>Start of Last Auto-Import</Text>
                    </Column>
                    <Column sm={8}>
                        <Text>{groupAutomationData?.last_auto_import_started_dt ? formatDateToTimeZone(groupAutomationData?.last_auto_import_started_dt) : 'Not Updated'}</Text>
                        {groupAutomationData?.last_auto_import_started_file && <Text>&#123;{groupAutomationData?.last_auto_import_started_file}&#125;</Text>}
                    </Column>
                </Row>
            </div>
        </PopupContent>
    );
};

AutomationStatusDetailsPopup.propTypes = {
    automationStatus: PropTypes.string
};

export {AutomationStatusDetailsPopup as TestableAutomationStatusDetailsPopup};
export default AutomationStatusDetailsPopup;

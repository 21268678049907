import React from 'react';
import {Outlet} from 'react-router-dom';
import withAuthorizationControl from '../../../HOC/withAuthorizationControl';
import Layout from '../../Authorization/Layout';
import Loader from '../../../Containers/shared/Loader';

const AuthorizationLayout = props => (
    <React.Fragment>
        <Loader/>

        <Layout>
            <Outlet {...props}/>
        </Layout>
    </React.Fragment>
);

export {AuthorizationLayout as testableAuthorizationLayout};
export default withAuthorizationControl({isAuthorizationPage: true})(AuthorizationLayout);

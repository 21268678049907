import {useEffect, useState} from 'react';
import {useBlocker} from 'react-router-dom';
import PropTypes from 'prop-types';
import {equal} from '../../../utils';

const ROUTER_BLOCKER_STATES = {
    blocked: 'blocked'
};

const RouterNavigationController = ({when, showPrompt, accessibleEntities, children}) => {
    const [isActive, setIsActive] = useState(false);

    const blocker = useBlocker(({currentLocation, nextLocation}) => {
        const checkEntityEntrance = entity => nextLocation.pathname.startsWith(entity);
        const isAccessible = accessibleEntities.length && accessibleEntities.some(checkEntityEntrance);
        const isBlocker = when && !equal(currentLocation.pathname, nextLocation.pathname);

        if (isAccessible) {
            return false;
        }

        if (isBlocker && children) {
            setIsActive(true);
        }

        return isBlocker;
    });

    useEffect(() => {
        if (!children && equal(blocker.state, ROUTER_BLOCKER_STATES.blocked)) {
            showPrompt(blocker.proceed, blocker.reset);
        }
    }, [blocker]);

    const childrenHandler = func => () => {
        setIsActive(false);
        func();
    };

    return children ? children(isActive, childrenHandler(blocker.proceed), childrenHandler(blocker.reset)) : null;
};

RouterNavigationController.propTypes = {
    when: PropTypes.bool.isRequired,
    showPrompt: PropTypes.func,
    accessibleEntities: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node
};

export {RouterNavigationController as TestableRouterNavigationController};
export default RouterNavigationController;

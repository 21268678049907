import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ContactInfoBasicForm from '../ContactInfoBasicForm';
import {showToast} from '../../../actions/shared';
import {requestContactInfo, requestContactUpdating} from '../../../actions/contacts';
import {ROUTES, FORMS} from '../../../constants';

const ExistedContactInfoForm = ({id}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        (async () => {
            const {data} = await dispatch(requestContactInfo(id));

            setInitialValues(data);
        })();
    }, []);

    const onSubmit = async values => {
        const {isSuccess, data, submissionErrors} = await dispatch(requestContactUpdating({...values, user_id: id}));

        if (!isSuccess) {
            return submissionErrors;
        }

        setInitialValues(data);
    };

    const onSubmitSuccess = () => {
        navigate(ROUTES.contacts);
        dispatch(showToast({content: 'Changes saved'}));
    };

    const basicFormProps = {id, formName: FORMS.existedContactInfo, initialValues, isExisted: true, onSubmit, onSubmitSuccess};

    return <ContactInfoBasicForm {...basicFormProps}/>;
};

ExistedContactInfoForm.propTypes = {
    id: PropTypes.string.isRequired
};

export {ExistedContactInfoForm as TestableExistedContactInfoForm};
export default ExistedContactInfoForm;

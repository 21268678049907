import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import PageHeader from '../../../Components/shared/PageHeader';
import ContactsBoard from '../../../Components/ContactsAndCompany/ContactsBoard';
import RolePermissions from '../../../Components/ContactsAndCompany/RolePermissions';
import CustomizablePopup from '../../../Components/shared/CustomizablePopup';
import withPopup from '../../../HOC/withPopup';
import {getProfilePermissions} from '../../../selectors/general';
import {ROUTES} from '../../../constants';
import './index.scss';

const POPUP_ID = 'contactsPopup';

const Contacts = ({openPopup}) => {
    const profilePermissions = useSelector(getProfilePermissions);
    const navigate = useNavigate();

    const onOpenRolePermissionsPopup = () => {
        const popupProps = {title: 'Roles and Permissions Details', content: <RolePermissions className='mb-12'/>};

        openPopup({type: POPUP_TYPES.simple, children: <CustomizablePopup {...popupProps}/>});
    };
    const onAddContact = () => navigate(ROUTES.contactInfo);

    const headerCaption = (
        <React.Fragment>
            View the listed contacts that support implementation or should view program performance post launch.&nbsp;
            <Button className='contacts__roles-permissions-button' type={BUTTON_TYPES.tertiary} onClick={onOpenRolePermissionsPopup} data-testid='roles-permissions-button'>
                View roles and permissions details
            </Button>
        </React.Fragment>
    );

    return (
        <div className='contacts'>
            <Row>
                <Column sm={12}>
                    <PageHeader title='Your Contacts' caption={headerCaption}>
                        {profilePermissions.contact_create && (
                            <Button iconLeft={<Icon type={ICON_TYPES.add}/>} onClick={onAddContact} data-testid='add-contact-button'>
                                Add Contact
                            </Button>
                        )}
                    </PageHeader>
                </Column>
            </Row>

            <ContactsBoard/>
        </div>
    );
};

Contacts.propTypes = {
    openPopup: PropTypes.func.isRequired
};

export {Contacts as TestableContacts};
export default withPopup(POPUP_ID)(Contacts);

export const CONFIG_ENDPOINT = '/config';
export const AUTH_ENDPOINT = '/auth';
export const TWO_STEP_ENDPOINT = '/two_step';
export const PROFILE_ENDPOINT = '/profile';
export const TERMS_AND_CONDITIONS_ENDPOINT = '/terms_conditions';
export const GROUPS_ENDPOINT = '/groups';
export const COMPANIES_ENDPOINT = '/companies';
export const TIMELINE_ENDPOINT = '/timeline';
export const TIMELINE_TEMPLATES_ENDPOINT = '/timeline_templates';
export const APP_CONFIGURATIONS_ENDPOINT = '/app_configurations';
export const SFTP_ENDPOINT = '/sftp';
export const RECOMMENDATIONS_MANAGER_ENDPOINT = '/recommendations_manager';
export const ELIGIBILITY_ENDPOINT = '/eligibility';
export const EXPORT_ENDPOINT = '/export';
export const JOBS_ENDPOINT = '/jobs';
export const INSURANCE_ENDPOINT = '/insurance';
export const WALLETS_ENDPOINT = '/wallets';
export const REPORTING_ENDPOINT = '/reports';
export const BROADCASTS_ENDPOINT = '/broadcasts';
export const EDUCATION_AND_COMMUNICATION_ENDPOINT = '/education_and_communication';
export const VERSION_HISTORY_ENDPOINT = '/version_history';
export const USER_ENDPOINT = '/user';
export const IMPORTS_ENDPOINT = '/imports';
export const SEGMENT_ENDPOINT = '/segment';

export const AUTOIMPORTS_ENDPOINT = '/autoimports';
export const TPA_PARTNERS_ENDPOINT = '/tpa-partners';
export const PROFILE_EXTERNAL_ENDPOINT = '/profile/external';

export const PROVIDER_BASKET_ENDPOINT = '/provider_basket';

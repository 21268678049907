import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import ReduxFormWizard from '@frontend/ui-kit/Components/Wizard';
import {setWizardActiveStep} from '../../../actions/shared';
import {getWizardActiveStep} from '../../../selectors/shared';

const WIZARD_INITIAL_STEP = 0;

const Wizard = props => {
    const {steps} = props;
    const dispatch = useDispatch();
    const activeStep = useSelector(getWizardActiveStep);

    useEffect(() => {
        return () => {
            dispatch(setWizardActiveStep(WIZARD_INITIAL_STEP));
        };
    }, [dispatch]);

    return <ReduxFormWizard {...props} activeStep={activeStep >= steps?.length ? WIZARD_INITIAL_STEP : activeStep}/>;
};

Wizard.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.object)
};

export {Wizard as TestableWizard};
export default React.memo(Wizard);

import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useParams, useNavigate} from 'react-router-dom';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Row from '@frontend/ui-kit/Components/Row';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import TPAGroup from '../TPAGroup';
import EligibilityImportsSearch from '../EligibilityImportsSearch';
import ImportConfigVersions from '../ImportConfigVersions';
import {requestCompaniesByTPAPartner} from '../../../actions/general';
import {ROUTES} from '../../../constants';
import {equal, isEmpty, parseQuery, stringifyQueryParams} from '../../../utils';

const GROUPS_PER_PAGE = 12;

const TPAPartner = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isConfiguredOnce, setIsConfiguredOnce] = useState(false);
    const [isCanModifyGroups, setIsCanModifyGroups] = useState(false);
    const {partner} = useParams();
    const {search, pathname} = useLocation();
    const {show_submit_alert: isSubmitAlertShown, partner_name: partnerName} = parseQuery(search);
    const partnerDisplayName = partnerName || partner;

    const onToggleGroup = ({target}) => {
        const groupAlias = target.name;
        const nextSelectedGroups = selectedGroups.includes(groupAlias)
            ? selectedGroups.filter(alias => alias !== groupAlias)
            : [...selectedGroups, groupAlias];

        setSelectedGroups(nextSelectedGroups);
    };

    const onGoToGroupDetails = (alias, title) => navigate(`${ROUTES.importsTpa}/${partner}/${alias}?partner_name=${encodeURIComponent(partnerDisplayName)}&group_name=${encodeURIComponent(title)}`);

    const onGoTTPAConfig = () => navigate(`${ROUTES.importsTpa}/${partner}/tpa_config?partner_name=${encodeURIComponent(partnerDisplayName)}#configuration`);

    const onGoToAllGroups = () => navigate(`${ROUTES.importsTpa}/${partner}/all?partner_name=${encodeURIComponent(partnerDisplayName)}`);

    const onGoToModifySelectedGroups = () => navigate(`${ROUTES.importsTpa}/${partner}/configure_tpa_groups?groups=${selectedGroups.join(',')}&partner_name=${encodeURIComponent(partnerDisplayName)}`);

    const getTpaGroup = ({alias, title}) => {
        const onToggle = isConfiguredOnce ? onToggleGroup : null;
        const onView = () => onGoToGroupDetails(alias, title);

        return (
            <Column sm={3} key={alias}>
                <TPAGroup value={selectedGroups.includes(alias)} name={alias} text={alias} onToggleGroup={onToggle} onView={onView}/>
            </Column>
        );
    };

    const onDismissSubmitAlert = () => {
        const {show_submit_alert, ...restSearch} = parseQuery(search);
        const enhancedSearch = stringifyQueryParams(restSearch);

        navigate(`${pathname}?${enhancedSearch}`, {replace: true});
    };

    const setLastSessionData = (sessions = [], {offset}) => {
        if (!equal(offset, 0)) {
            return false;
        }

        const isGlobalErrors = sessions[0]?.preprocessing_report?.global_errors?.length;

        setIsCanModifyGroups(!isGlobalErrors);
        setIsConfiguredOnce(!!sessions?.length);
    };

    useEffect(() => {
        (async () => {
            const {groupsData: newLaunch} = await dispatch(requestCompaniesByTPAPartner({partnerId: partner, limit: GROUPS_PER_PAGE}));

            setGroups(newLaunch.data);
        })();
    }, [dispatch, partner]);

    return (
        <div className='tpa-partner'>
            {isSubmitAlertShown && (
                <Alert className='mb-20' type={ALERT_TYPES.success} data-testid='submit-alert' description={`${partnerDisplayName} TPA Pre-processing has been submitted. Track progress below.`}>
                    <Icon type={ICON_TYPES.close} onClick={onDismissSubmitAlert}/>
                </Alert>
            )}

            <Alert className='mb-20' type={ALERT_TYPES.warning} description='Renewals are not supported in the Admin Portal at this time. Please see Houston to configure and upload other remaining groups'/>

            <Row className='mb-20' middle='xs'>
                <Column sm>
                    <Heading type={HEADING_TYPES['1']} data-testid='partner-name'>{partnerDisplayName}</Heading>
                </Column>

                <Column constant className='mr-8'>
                    <ImportConfigVersions/>
                </Column>

                {!!selectedGroups.length && (
                    <Column constant className='mr-8'>
                        <Button data-testid='modify-selected-Groups-button' disabled={!isCanModifyGroups} onClick={onGoToModifySelectedGroups}>Modify Selected Groups ({selectedGroups.length})</Button>
                    </Column>
                )}

                <Column constant className='pr-5'>
                    <Button data-testid='configure-tpa-button' disabled={isEmpty(groups)} onClick={onGoTTPAConfig}>Configure TPA</Button>
                </Column>
            </Row>

            <Heading type={HEADING_TYPES['3']}>TPA Groups</Heading>

            <Row rowGap='md' className='mt-10'>
                {groups.map(getTpaGroup)}
            </Row>

            <Row end='xs' className='mb-20'>
                <Button data-testid='view-all-tpa-groups-button' onClick={onGoToAllGroups} type={BUTTON_TYPES.tertiary}>
                    View All TPA Groups
                    <Icon type={ICON_TYPES.arrowRight} className='ml-2'/>
                </Button>
            </Row>

            <ContentSection>
                <EligibilityImportsSearch partnerId={partner} setImportsData={setLastSessionData}/>
            </ContentSection>
        </div>
    );
};

export {TPAPartner as TestableTPAPartner, GROUPS_PER_PAGE};
export default React.memo(TPAPartner);

import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import StrongValidation from '@frontend/ui-kit/Components/StrongValidation';
import StrongValidationMessage from '@frontend/ui-kit/Components/StrongValidationMessage';
import GlobalErrorMessage from '@frontend/ui-kit/Components/GlobalErrorMessage';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {Form, FormSpy} from '../../shared/FormComponents';
import PasswordInput from '../../shared/PasswordInput';
import {requestGrantTokenChecking, requestPasswordSetting} from '../../../actions/authorization';
import {equal, getMatches, parseQuery, validatePasswordConfirm, validateStrongPassword} from '../../../utils';
import {FORMS, ROUTES, PASSWORD_RULES, PASSWORD_STRENGTHS_RULES, FORM_GLOBAL_ERROR, POLICY_URLS} from '../../../constants';

const FORM_SPY_SUBSCRIPTION = {active: true};

/* istanbul ignore next */
const validate = values => ({
    password: validateStrongPassword(values.password),
    password_confirm: validatePasswordConfirm(values.password_confirm, values.password)
});

const ActivationForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [lastFocusedField, setLastFocusedField] = useState(null);
    const [initialValues, setInitialValues] = useState({password: '', password_confirm: ''});
    const location = useLocation();
    const {user_id: userId, token} = parseQuery(location.search);

    useEffect(() => {
        (async () => {
            const {isSuccess} = await dispatch(requestGrantTokenChecking(userId, token));

            if (!isSuccess) {
                navigate(ROUTES.pageNotFound);
            }
        })();
    }, []);

    const onChangeActiveField = useCallback(({active: lastFocusedField}) => {
        if (!lastFocusedField) {
            return false;
        }

        setLastFocusedField(lastFocusedField);
    }, []);

    const onSubmit = useCallback(async values => {
        const {isSuccess, submissionGlobalError} = await dispatch(requestPasswordSetting(userId, token, values.password));

        if (!isSuccess) {
            return {[FORM_GLOBAL_ERROR]: submissionGlobalError};
        }

        setInitialValues(values);
    }, [dispatch, userId, token]);

    const onSubmitSuccess = useCallback(() => navigate(ROUTES.login), []);

    return (
        <Form name={FORMS.activation} initialValues={initialValues} validate={validate} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit, values, submitError}) => {
                const {password, password_confirm: passwordConfirm} = values;
                const strengthsMatches = getMatches(PASSWORD_STRENGTHS_RULES, password);
                const validationsMatches = getMatches(PASSWORD_RULES, password);
                const {maxLength, uppercaseLetter, number, specCharacter} = validationsMatches;

                const isPasswordValid = Object.values(validationsMatches).every(Boolean);
                const arePasswordsMatched = equal(password, passwordConfirm);
                const isSubmitDisabled = !isPasswordValid || !arePasswordsMatched;

                return (
                    <form className='authorization-form authorization-form_activation' onSubmit={handleSubmit} noValidate>
                        <FormSpy subscription={FORM_SPY_SUBSCRIPTION} onChange={onChangeActiveField}/>

                        <div className='authorization-form-header'>
                            <Heading className='authorization-form-header__title' type={HEADING_TYPES['1']}>Create Password</Heading>
                            <Text className='authorization-form-header__subtitle'>
                                Welcome to the HealthJoy dashboard! Please create a password.
                            </Text>
                        </div>

                        <div className='authorization-form-body'>
                            <PasswordInput name='password' placeholder='Enter a password...' isValidationDisplayed={false} wrapperClassName='mb-8' label='Enter Password'/>
                            {equal(lastFocusedField, 'password') && (
                                <div className='authorization-form-body__validation'>
                                    <StrongValidation password={password} strengthsMatches={strengthsMatches}/>

                                    <StrongValidationMessage isSuccess={uppercaseLetter}>At least one uppercase letter</StrongValidationMessage>
                                    <StrongValidationMessage isSuccess={number}>At least one number</StrongValidationMessage>
                                    <StrongValidationMessage isSuccess={specCharacter}>At least one special character</StrongValidationMessage>
                                    <StrongValidationMessage isSuccess={maxLength}>Maximum of 256 characters</StrongValidationMessage>
                                </div>
                            )}

                            <PasswordInput name='password_confirm' placeholder='Re-enter your password...' isValidationDisplayed={false} wrapperClassName='mb-8' label='Confirm Password'/>
                            {equal(lastFocusedField, 'password_confirm') && isPasswordValid && (
                                <StrongValidationMessage isSuccess={arePasswordsMatched}>Passwords must match</StrongValidationMessage>
                            )}
                            Already registered? <Link href={ROUTES.login}>Login</Link>

                            {submitError && <GlobalErrorMessage className='authorization-form-body__error-message'>{submitError}</GlobalErrorMessage>}

                            <div className='authorization-form-action-bar'>
                                <Button className='authorization-form-action-bar__button' type={BUTTON_TYPES.primary} disabled={isSubmitDisabled} isSubmit>Register</Button>
                            </div>
                            <Text type={TEXT_TYPES.helper}>
                                By selecting Register, you accept our&nbsp;
                                <Link href={POLICY_URLS.termsAndConditions} target='_blank'>Client Terms and Conditions</Link>,&nbsp;
                                <Link href={POLICY_URLS.termsOfUse} target='_blank'>Terms of Use</Link>, and&nbsp;
                                <Link href={POLICY_URLS.privacyPolicy} target='_blank'>Privacy Policy</Link>.
                            </Text>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export {ActivationForm as TestableActivationForm};
export default ActivationForm;

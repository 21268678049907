import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Client from '../Client';
import {requestTPAPartners} from '../../../actions/general';
import {ROUTES} from '../../../constants';
import {isEmpty} from '../../../utils';
import {getRecentlyViewedClients, setRecentlyViewedClients} from '../../../helpers';

const RecentlyViewedClients = () => {
    const navigate = useNavigate();
    const [viewedClients, setViewedClients] = useState(getRecentlyViewedClients());
    const clients = useMemo(() => viewedClients.slice(0, 4), [viewedClients]);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (isEmpty(viewedClients)) {
                const {partners, isSuccess} = await dispatch(requestTPAPartners({offset: 0, limit: 4}));

                if (isSuccess) {
                    setRecentlyViewedClients(partners);
                    setViewedClients(partners);
                }
            }
        })();
    }, []);

    return (
        <div className='mb-20'>
            <Heading type={HEADING_TYPES['3']}>Recently Viewed Clients</Heading>

            <Row className='mt-10'>
                {!isEmpty(clients) && clients.map(client => (
                    <Column key={client.alias} sm={3}>
                        <Client client={client}/>
                    </Column>
                ))}
            </Row>

            <Row className='mt-10'>
                <Column sm/>
                <Column constant>
                    <Button data-testid='view-all-clients-button' type={BUTTON_TYPES.tertiary} onClick={() => navigate(ROUTES.importsIndividual)}>
                        View All Clients
                        <Icon className='ml-3' type={ICON_TYPES.arrowRight}/>
                    </Button>
                </Column>
            </Row>
        </div>
    );
};

export {RecentlyViewedClients as TestableRecentlyViewedClients};
export default React.memo(RecentlyViewedClients);

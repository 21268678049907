import React from 'react';
import PropTypes from 'prop-types';
import ReduxActionsAdapter from './ReduxActionsAdapter';

const reduxActionsAdapter = new ReduxActionsAdapter();
const actionNavigationControllerMiddleware = () => next => action => {
    reduxActionsAdapter.setAction(action);
    const isActionBlocked = reduxActionsAdapter.checkIsBlocked();

    if (isActionBlocked) {
        return false;
    }

    next(action);
};

const ActionNavigationController = props => {
    const navigationControllerProps = {
        coerceNextEntity: ({type}) => type,
        nextAction: reduxActionsAdapter.nextAction,
        bindBlock: reduxActionsAdapter.block,
        ...props
    };

    return <BaseController {...navigationControllerProps}/>;
};

class BaseController extends React.Component {
    state = {
        isActive: false,
        nextEntity: null,
        unbindBlock: null
    };

    componentDidMount = () => this.registerListener();

    componentWillUnmount = () => this.state.unbindBlock();

    registerListener = () => {
        const {bindBlock} = this.props;
        const unbindBlock = bindBlock(this.onBlock);

        this.setState({unbindBlock});
    };

    onBlock = nextEntity => {
        const {when, rejectableEntities, children, showPrompt, coerceNextEntity} = this.props;
        const coercedEntity = coerceNextEntity(nextEntity);
        const checkEntityEntrance = entity => coercedEntity.startsWith(entity);
        const isUnrejectable = rejectableEntities.length && !rejectableEntities.some(checkEntityEntrance);

        if (isUnrejectable) {
            return true;
        }

        if (when) {
            this.setState({isActive: true, nextEntity});

            if (children) {
                return false;
            }

            showPrompt(this.onConfirm, this.onCancel);
        }

        return !when;
    };

    onCancel = () => this.setState({nextEntity: null, isActive: false});

    onConfirm = () => {
        const {applyEntity} = this.props;
        const {unbindBlock, nextEntity} = this.state;

        unbindBlock();
        applyEntity(nextEntity);
        this.setState({nextEntity: null, isActive: false}, this.registerListener);
    };

    render = () => {
        const {isActive} = this.state;
        const {children} = this.props;

        return children ? children(isActive, this.onConfirm, this.onCancel) : null;
    };
}

BaseController.propTypes = {
    when: PropTypes.bool.isRequired,
    children: PropTypes.func,
    showPrompt: PropTypes.func,
    coerceNextEntity: PropTypes.func,
    rejectableEntities: PropTypes.arrayOf(PropTypes.string),
    bindBlock: PropTypes.func,
    applyEntity: PropTypes.func
};

BaseController.defaultProps = {
    rejectableEntities: [],
    coerceNextEntity: entity => entity
};

export {actionNavigationControllerMiddleware, BaseController as TestableBaseController};
export default ActionNavigationController;

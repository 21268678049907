import {
    RECEIVE_BASIC_DATA,
    RECEIVE_ADMIN_PORTAL_BASIC_DATA,
    RECEIVE_COMPANIES_MAP,
    RECEIVE_MARKETING_PAGES,
    RECEIVE_TIMELINE,
    SET_ACTIVE_COMPANY,
    SET_STEP_ALIAS,
    SET_BROADCASTER_ONBOARDED,
    UPDATE_ACTIVE_COMPANY,
    SET_WORKSPACE,
    SET_USER_ONBOARDED_DATA,
    SET_REDIRECT_TO_URL,
    RESET_REDIRECT_TO_URL,
    SET_SERVER_ERROR_STATUS
} from '../actions/actionTypes';
import {WORKSPACES} from '../constants';

export const initialState = {
    workspace: WORKSPACES.dashboard,
    profileInfo: {
        user_onboarded: []
    },
    companies: {
        companiesMap: {},
        activeCompany: {
            all_plan_periods: []
        }
    },
    timeline: {steps: [], owner: {}, implementation_manager: {}, isExisted: null},
    stepAlias: null,
    marketingPages: [],
    redirectToUrl: null,
    serverErrorStatus: null
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case SET_WORKSPACE: {
            const {workspace} = payload;

            return {...state, workspace};
        }

        case RECEIVE_BASIC_DATA: {
            const {profileInfo, activeCompany, timeline} = payload;

            return {...state, profileInfo, companies: {...state.companies, activeCompany}, timeline};
        }

        case RECEIVE_ADMIN_PORTAL_BASIC_DATA: {
            const {profileInfo} = payload;

            return {...state, profileInfo};
        }

        case SET_USER_ONBOARDED_DATA: {
            const {onboardedTypes} = payload;
            const profileInfo = {...state.profileInfo, user_onboarded: onboardedTypes};

            return {...state, profileInfo};
        }

        case RECEIVE_COMPANIES_MAP: {
            const companiesMap = {...state.companies.companiesMap, ...payload.companiesMap};
            const companies = {...state.companies, companiesMap};

            return {...state, companies};
        }

        case SET_ACTIVE_COMPANY: {
            const {activeCompany} = payload;
            const companies = {...state.companies, activeCompany};

            return {...state, companies};
        }

        case UPDATE_ACTIVE_COMPANY: {
            const {companies} = state;
            const activeCompany = {...companies.activeCompany, ...payload.companyDetails};
            const companiesMap = {...companies.companiesMap, [activeCompany.name]: activeCompany};

            return {...state, companies: {...companies, companiesMap, activeCompany}};
        }

        case RECEIVE_TIMELINE: {
            const {timeline} = payload;

            return {...state, timeline};
        }

        case SET_STEP_ALIAS: {
            const {stepAlias} = payload;

            return {...state, stepAlias};
        }

        case RECEIVE_MARKETING_PAGES: {
            const {marketingPages} = payload;

            return {...state, marketingPages};
        }

        case SET_BROADCASTER_ONBOARDED: {
            const {profileInfo} = state;

            return {...state, profileInfo: {...profileInfo, is_broadcaster_onboarded: true}};
        }

        case SET_SERVER_ERROR_STATUS: {
            const {serverErrorStatus} = payload;

            return {...state, serverErrorStatus};
        }

        case SET_REDIRECT_TO_URL: {
            const {url} = payload;

            return {...state, redirectToUrl: url};
        }

        case RESET_REDIRECT_TO_URL: {
            return {...state, redirectToUrl: initialState.redirectToUrl};
        }

        default:
            return state;
    }
};

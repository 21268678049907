import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Input from '@frontend/ui-kit/Components/Input';
import {Form, Field} from '../../shared/FormComponents';
import DynamicActionBar from '../../shared/DynamicActionBar';
import FileUploader from '../../shared/FileUploader';
import WysiwygEditor, {getHtmlFromDraft, getDraftFromHtml} from '../../shared/WysiwygEditor';
import ImageSelector from './ContentStepFields/ImageSelectorField';
import AdditionalContentFields from './ContentStepFields/AdditionalContentFields';
import BroadcastPreview from './BroadcastPreview';
import withBroadcastStepCommon from '../../../HOC/withBroadcastStepCommon';
import useBroadcast from '../../../hooks/useBroadcast';
import {requestWalletLinks, requestAudience} from '../../../actions/broadcaster';
import {showToast} from '../../../actions/shared';
import {getWalletLinks, getBroadcast, getBroadcastAudience} from '../../../selectors/broadcaster';
import {getActiveCompany} from '../../../selectors/general';
import {
    equal,
    validateRequired,
    validateSecureLink,
    validateMaxLength,
    getTextFromHtml,
    delay
} from '../../../utils';
import {
    FORMS,
    BROADCAST_STATUSES,
    BROADCASTER_LINK_TYPES,
    WYSIWYG_TOOLBAR_OPTIONS,
    HJ_CDN_IMAGE_BASE_URL
} from '../../../constants';
import hjLogoBox from '../../../static/images/hjLogoBox.svg';
import './index.scss';

const CARRIER_LOGO_NAME = 'content.image_url';
const LOGO_FILE_MAX_SIZE = 5;
const ACCEPTABLE_IMAGE_FORMATS = ['.jpg', '.jpeg', '.png', '.svg'];
const DESCRIPTION_CHARS_LIMIT = 100;
const TOAST_VISIBILITY_TIME = 2000;

const ITEM_TRANSFORMERS = {
    walletCard: block => ({
        type: 'wallet_link',
        link: block.link,
        link_title: block.name
    }),
    document: block => ({
        type: 'file',
        description: block.name,
        media_url: block.description
    }),
    additionalText: block => ({
        type: 'text',
        body: block.body.__html ? block.body.__html : getHtmlFromDraft(block.body)
    })
};

const transformBlock = block => {
    const transformer = ITEM_TRANSFORMERS[block.type];
    return transformer ? transformer(block) : block;
};

const getNormalizedDetails = value => getTextFromHtml(value).trim();

const validate = values => {
    const {content = {}} = values;
    const {link, link_type: linkType, blocks = []} = content ?? {};
    const normalizedDetails = content?.details && getNormalizedDetails(content.details);
    const validateLink = {
        [BROADCASTER_LINK_TYPES.wallet]: () => undefined,
        [BROADCASTER_LINK_TYPES.file]: () => undefined,
        [BROADCASTER_LINK_TYPES.web]: link => validateSecureLink(link)
    }[linkType ?? BROADCASTER_LINK_TYPES.wallet];

    const blockErrors = blocks.map(({type, description, link}) => {
        const blockDescription = equal(type, 'document') && !description && 'File description is required';
        const blockWalletLink = equal(type, 'walletCard') && !link && 'Wallet link is required';

        return (blockDescription || blockWalletLink)
            ? {...blockDescription && {description: blockDescription}, ...blockWalletLink && {link: blockWalletLink}}
            : undefined;
    });

    return {
        content: {
            title: validateRequired(content.title) || validateMaxLength(content.title, 40),
            details: validateRequired(normalizedDetails) || validateMaxLength(normalizedDetails, 500),
            link: link ? validateLink(link) : undefined,
            blocks: blockErrors.some(Boolean) ? blockErrors : undefined
        }
    };
};

const getEnhancedValues = (walletLinks, broadcastAudience, values) => {
    const {content, condition_templates_arguments: conditionTemplatesArguments, ...restValues} = values;
    const {title, link, link_title: linkTitle, details, blocks, ...restEntry} = content;

    const transformedBlocks = blocks.map(transformBlock);
    const normalizedDetails = details.__html || getHtmlFromDraft(details);

    return {
        ...restValues,
        name: title,
        status: BROADCAST_STATUSES.drafting,
        content: {...content, ...restEntry, blocks: transformedBlocks, details: normalizedDetails, type: 'broadcast'},
        segment: broadcastAudience?.segment
    };
};

const ContentStep = props => {
    const dispatch = useDispatch();
    const walletLinks = useSelector(getWalletLinks);
    const broadcastAudience = useSelector(getBroadcastAudience);
    const {id} = useParams();
    const selectedBroadcast = useSelector(getBroadcast);
    const broadcastId = id || selectedBroadcast?.id;
    const {logo} = useSelector(getActiveCompany) || {};
    const broadcast = useBroadcast(broadcastId);
    const [pathTo, setPathTo] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [images, setImages] = useState([
        ...(logo ? [logo] : []),
        `${HJ_CDN_IMAGE_BASE_URL}/broadNewbgOne.png`,
        `${HJ_CDN_IMAGE_BASE_URL}/broadNewbgTwo.png`
    ]);

    useEffect(() => {
        dispatch(requestWalletLinks());
    }, [dispatch]);

    const clearAudiance = () => {
        dispatch(requestAudience({
            company_alias: null,
            states: [],
            divisions: [],
            medical_plans: [],
            dental_plans: [],
            vision_plans: [],
            count: null
        }));
    };

    const onSubmit = async values => {
        const {submit} = props;
        setInitialValues(values);
        const enhancedValues = getEnhancedValues(walletLinks, broadcastAudience, values);

        if (!pathTo) {
            submit(enhancedValues, pathTo);
            clearAudiance();
            return;
        }
        dispatch(showToast({content: 'Draft saved!', visibilityTime: TOAST_VISIBILITY_TIME}));
        delay(() => submit(enhancedValues, pathTo), TOAST_VISIBILITY_TIME);
        clearAudiance();
    };
    const initialData = initialValues || broadcast;

    const initialImage = initialData?.content.image_url;
    const normalizeWysiwygEditorValue = value => ({__html: getHtmlFromDraft(value)});
    const draftWysiwygEditorValue = value => {
        return value.__html ? getNormalizedDetails(value.__html) : getNormalizedDetails(getHtmlFromDraft(value));
    };

    const transformBroadcastValues = values => ({
        ...values,
        content: {
            ...values.content,
            details: values?.content?.details?.__html ? getDraftFromHtml(values.content.details.__html) : values.content.details,
            blocks: values?.content?.blocks?.map(block => {
                if (equal(block.type, 'additionalText')) {
                    return {
                        ...block,
                        body: block?.body?.__html ? getDraftFromHtml(block?.body?.__html) : block.body
                    };
                }
                return block;
            })
        }
    });

    return (
        <div>
            {initialData && (
                <Form name={FORMS.broadcastContentStep} initialValues={initialData} onSubmit={onSubmit} validate={validate}>
                    {({handleSubmit, values, form}) => {
                        const onClickSubmitButton = (isTest, pathTo) => () => {
                            setPathTo(pathTo);
                            form.change('is_test', isTest);
                        };
                        const onSelectImage = imageSrc => form.change(CARRIER_LOGO_NAME, imageSrc);
                        const onUploadFile = file => {
                            if (file) {
                                setImages(prevImages => [...prevImages, file]);
                            }
                            onSelectImage(file);
                        };
                        const contentDetails = values?.content?.details || '';
                        const preparedDetailsText = contentDetails ? draftWysiwygEditorValue(contentDetails) : '';
                        const truncatedText = preparedDetailsText.slice(0, DESCRIPTION_CHARS_LIMIT);
                        const isEllipsis = truncatedText && truncatedText.length >= DESCRIPTION_CHARS_LIMIT;
                        const previewDetails = isEllipsis ? `${truncatedText}...` : preparedDetailsText;
                        const isDefaultImage = images.some(image => equal(image, values?.content?.image_url));

                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <Row className='broadcast-wizard-step'>
                                    <Column sm={7} className='broadcast-section'>
                                        <Heading type={HEADING_TYPES['6']} className='mb-8 mt-25'>Title</Heading>
                                        <Field name='content.title'>
                                            {props => <Input {...props} maxLength={40} data-testid='input-title' placeholder='Give your message a title...' label='Title' isRequired wrapperClassName='mb-12'/>}
                                        </Field>

                                        <Heading type={HEADING_TYPES['6']} className='mb-8 mt-24'>Image</Heading>
                                        <ImageSelector onSelect={onSelectImage} images={images} isDefaultImage={isDefaultImage} initialImage={initialImage}/>

                                        <Field name={CARRIER_LOGO_NAME} data-testid='input-logo'>
                                            {props => (
                                                <FileUploader {...props} maxSize={LOGO_FILE_MAX_SIZE} accept={ACCEPTABLE_IMAGE_FORMATS} wrapperClassName='mb-12' onChange={onUploadFile} fileNameReplacePattern={{from: ' ', to: '_'}}/>
                                            )}
                                        </Field>
                                        <Heading type={HEADING_TYPES['6']} className='mb-8 mt-24'>Details</Heading>
                                        <Field name='content.details' parse={normalizeWysiwygEditorValue}>
                                            {props => (
                                                <WysiwygEditor {...props}
                                                    maxLength={500}
                                                    toolbar={WYSIWYG_TOOLBAR_OPTIONS}
                                                    data-testid='input-details'
                                                    className='mb-12'
                                                    wrapperClassName='editor-wrapper'
                                                    toolbarClassName='wysiwyg-toolbar'
                                                    editorClassName='wysiwyg-editor'
                                                    placeholder='Add details and hyperlinks to your message...'/>
                                            )}
                                        </Field>

                                        <AdditionalContentFields/>
                                    </Column>

                                    <Column sm={5} className='broadcast-section'>
                                        <div className='broadcast-phone-preview' data-testid='phone-preview'>
                                            <Heading type={HEADING_TYPES['6']} className='title'>Preview</Heading>
                                            <Text className='subtitle'>Notifications may vary slightly depending on personal device and settings.</Text>
                                            <div className='push-notification'>
                                                <img src={hjLogoBox} alt=''/>
                                                <div className='push-notification-text'>
                                                    <div className='push-notification-text__title'>{values?.content?.title || 'Title will preview here'}</div>
                                                    <div className='push-notification-text__subtitle'>{previewDetails || 'A preview of message details will display here...'}</div>
                                                </div>
                                            </div>
                                            <div className='broadcast-preview-wrapper'>
                                                <BroadcastPreview broadcastValues={transformBroadcastValues(values)}/>
                                            </div>
                                        </div>
                                    </Column>
                                </Row>

                                <DynamicActionBar>
                                    <Button type={BUTTON_TYPES.tertiary} isSubmit onClick={onClickSubmitButton(false, '/broadcaster')} data-testid='button-wizard-save-draft'>
                                        Save as Draft
                                    </Button>

                                    <Button type={BUTTON_TYPES.primary} isSubmit onClick={onClickSubmitButton(false)} data-testid='button-wizard-continue'>
                                        Continue
                                    </Button>
                                </DynamicActionBar>
                            </form>
                        );
                    }}
                </Form>
            )}
        </div>
    );
};

ContentStep.propTypes = {
    submit: PropTypes.func.isRequired
};

export {ContentStep as TestableContentStep};
export default withBroadcastStepCommon(ContentStep);

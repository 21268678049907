export const getAuthorizationStatus = state => state.authorization.isAuthorized;

export const getAccessToken = state => state.authorization.accessToken;

export const getRefreshToken = state => state.authorization.refreshToken;

export const getTwoStepToken = state => state.authorization.twoStepToken;

export const getTwoStepCodeChannel = state => state.authorization.twoStepCodeChannel;

export const getIsTwoStepRequired = state => state.authorization.isTwoStepRequired;

import React, {useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Form as SimpleForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import FormSpy from '../FormSpy';
import scrollToErrorDecorator from './scrollToErrorDecorator';
import {setFormDirtiness} from '../../../../actions/shared';
import {equal} from '../../../../utils';

const FORM_SPY_SUBSCRIPTION = {dirty: true, submitSucceeded: true};

const Form = ({name, children, mutators, decorators = [], onSubmitSuccess, isScrollToError = true, ...restProps}) => {
    const dispatch = useDispatch();
    const formDirtinessRef = useRef(false);

    const onChangeFormSpy = formState => ({dirty: isDirty, submitSucceeded: isSubmitSucceeded}) => {
        if (!equal(formDirtinessRef.current, isDirty)) {
            dispatch(setFormDirtiness({formName: name, isDirty}));

            formDirtinessRef.current = isDirty;
        }

        if (isDirty || !isSubmitSucceeded || !onSubmitSuccess) {
            return false;
        }

        // FYI: Navigation in onSubmitSuccess: useBlocker in RouterNavigationController calls before dirty value updated and onWarnAboutUnsavedForm appears.
        // To avoid this we need to update form dirtiness manually before onSubmitSuccess calls (02.20.2025, Pasha)
        dispatch(setFormDirtiness({formName: name, isDirty: false}));
        onSubmitSuccess(formState);
    };

    useEffect(() => {
        return () => dispatch(setFormDirtiness({formName: name, isDirty: undefined}));
    }, [dispatch, name]);

    const enhancedDecorators = useMemo(() => {
        return [...decorators, isScrollToError ? scrollToErrorDecorator : null].filter(Boolean);
    }, [decorators, isScrollToError]);

    const formProps = {
        mutators: {...mutators, ...arrayMutators},
        decorators: enhancedDecorators,
        ...restProps
    };

    return (
        <SimpleForm {...formProps}>
            {props => (
                <React.Fragment>
                    <FormSpy subscription={FORM_SPY_SUBSCRIPTION} onChange={onChangeFormSpy(props)}/>

                    {children(props)}
                </React.Fragment>
            )}
        </SimpleForm>
    );
};

Form.propTypes = {
    name: PropTypes.string.isRequired,
    isScrollToError: PropTypes.bool,
    children: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({}),
    decorators: PropTypes.arrayOf(PropTypes.func),
    mutators: PropTypes.shape({}),
    validate: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func
};

export {Form as TestableForm};
export default React.memo(Form);

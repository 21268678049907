import React, {useEffect, useState, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import Autocomplete from '@frontend/ui-kit/Components/Autocomplete';
import Select from '@frontend/ui-kit/Components/Select';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import RadioGroup from '@frontend/ui-kit/Components/RadioGroup';
import DynamicActionBar from '../../shared/DynamicActionBar';
import {Form, Field} from '../../shared/FormComponents';
import withBroadcastStepCommon from '../../../HOC/withBroadcastStepCommon';
import useBroadcast from '../../../hooks/useBroadcast';
import {getBroadcastAudience, getBroadcast} from '../../../selectors/broadcaster';
import {getTimeline} from '../../../selectors/general';
import {requestAudience} from '../../../actions/broadcaster';
import {requestCorePlansData} from '../../../actions/benefits';
import {goNextWizardStep} from '../../../actions/shared';
import {validateRequired, equal, formatDate, isEmpty, getEqual} from '../../../utils';
import {FORMS, CORE_PLAN_TYPES} from '../../../constants';
import {
    AUDIENCE_FILTER_OPTIONS,
    SPECIFIC_AUDIENCE_FILTER_OPTIONS,
    STATES_OPTIONS,
    DIVISIONS_OPTIONS
} from '../../../options';
import './index.scss';

const AUDIENCE_TYPES = {
    all: 'all_audience',
    specific: 'specific_audience'
};

const validate = values => {
    const errors = {};

    errors.audience = validateRequired(values.audience);

    if (equal(values.audience, AUDIENCE_TYPES.specific)) {
        errors.filter = validateRequired(values.filter);

        const filterOptions = {
            locations: {name: 'states'},
            medical_plans: {name: 'medical_plans'},
            divisions: {name: 'divisions'}
        };

        const selectedFilterName = filterOptions[values.filter]?.name;
        if (selectedFilterName) {
            errors[selectedFilterName] = validateRequired(values[selectedFilterName]);
        }
    }

    return errors;
};

const PLAN_TYPES = [CORE_PLAN_TYPES.medical, CORE_PLAN_TYPES.vision, CORE_PLAN_TYPES.dental];

const AudienceStep = () => {
    const dispatch = useDispatch();
    const [isSpecificAudience, setIsSpecificAudience] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [medicalPlansOptions, setMedicalPlansOptions] = useState([]);
    const broadcastAudience = useSelector(getBroadcastAudience);
    const {plan_period_id: planPeriodId} = useSelector(getTimeline);
    const {id} = useParams();
    const selectedBroadcast = useSelector(getBroadcast);
    const broadcastId = id || selectedBroadcast?.id;
    const broadcast = useBroadcast(broadcastId);
    const segment = broadcast?.segment || {};

    useEffect(() => {
        (async () => {
            const planRequests = PLAN_TYPES.map(type => dispatch(requestCorePlansData(planPeriodId, type)));
            const plansData = await Promise.all(planRequests);

            const mapPlansToOptions = plans => plans?.map(plan => ({
                label: plan.app_name,
                value: plan.id
            })) || [];

            setMedicalPlansOptions(plansData.flatMap(data => mapPlansToOptions(data.plans)));
        })();
    }, [dispatch, planPeriodId]);

    const FILTER_OPTIONS_MAP = useMemo(() => ({
        locations: {name: 'states', label: 'State', options: STATES_OPTIONS},
        medical_plans: {name: 'medical_plans', label: 'Medical plan', options: medicalPlansOptions},
        divisions: {name: 'divisions', label: 'Division', options: DIVISIONS_OPTIONS}
    }), [medicalPlansOptions]);

    const loadedSelectedFilter = useMemo(() => {
        return Object.keys(FILTER_OPTIONS_MAP).find(key => !isEmpty(segment[FILTER_OPTIONS_MAP[key].name])) || null;
    }, [segment, FILTER_OPTIONS_MAP]);

    const hasSpecificAudience = useMemo(
        () => Object.values(segment).some(value => !isEmpty(value)),
        [segment]
    );

    const loadedInitialValues = hasSpecificAudience
        ? {
            audience: AUDIENCE_TYPES.specific,
            filter: loadedSelectedFilter || null,
            states: segment.states || [],
            divisions: segment.divisions || [],
            medical_plans: segment.medical_plans || [],
            dental_plans: segment.dental_plans || [],
            vision_plans: segment.vision_plans || []
        }
        : null;

    const initialValues = loadedInitialValues || {audience: AUDIENCE_FILTER_OPTIONS.find(getEqual(AUDIENCE_TYPES.all, 'value'))?.value};
    const today = formatDate(new Date(), 'M/d/yyyy');

    useEffect(() => {
        if (!hasSpecificAudience) {
            dispatch(requestAudience({}));
        }
    }, [dispatch, hasSpecificAudience]);

    useEffect(() => {
        if (hasSpecificAudience && selectedFilter !== loadedSelectedFilter) {
            setIsSpecificAudience(true);
            setSelectedFilter(loadedSelectedFilter);
        }
    }, [hasSpecificAudience, loadedSelectedFilter]);

    const onSubmit = () => dispatch(goNextWizardStep());

    const handleAudienceChange = (name, value) => {
        const updatedValue = value.length ? value : null;

        dispatch(requestAudience({[name]: updatedValue}));
    };

    const renderFilterField = form => {
        if (!selectedFilter || !FILTER_OPTIONS_MAP[selectedFilter]) {
            return null;
        }

        const filterConfig = FILTER_OPTIONS_MAP[selectedFilter];

        const onRemoveFilter = () => {
            form.batch(() => {
                ['filter', 'states', 'medical_plans', 'divisions'].forEach(field => form.change(field, null));
            });
            setSelectedFilter(null);
            dispatch(requestAudience({}));
        };

        return (
            <Row className='segmentation-field'>
                <Column sm={11}>
                    <Field name={filterConfig.name} onChange={value => handleAudienceChange(filterConfig.name, value)}>
                        {props => (
                            <Autocomplete {...props} options={filterConfig.options} label={filterConfig.label} isRequired isMulti/>
                        )}
                    </Field>
                </Column>

                <Column sm={1}>
                    <Icon className='trash-icon' type={ICON_TYPES.delete} onClick={onRemoveFilter}/>
                </Column>
            </Row>
        );
    };

    return (
        <Form name={FORMS.broadcastAudienceStep} onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
            {({handleSubmit, values, form}) => {
                const isAudiencePresent = !!segment.count || !!broadcastAudience?.segment.count;
                const audienceCount = segment.count ?? broadcastAudience?.segment.count ?? 0;
                const isDisabled = !equal(values.audience, AUDIENCE_TYPES.all) && !isAudiencePresent;
                const resetFields = (form, fields) => {
                    form.batch(() => {
                        fields.forEach(field => form.change(field, null));
                    });
                };
                const onChangeAudience = ({target}) => {
                    const isSpecific = equal(target.value, AUDIENCE_TYPES.specific);

                    if (!isSpecific) {
                        resetFields(form, ['filter', 'states', 'medical_plans', 'divisions']);
                        setSelectedFilter(null);

                        dispatch(requestAudience({}));
                    }

                    setIsSpecificAudience(isSpecific);
                };

                const onChangeFilter = value => {
                    resetFields(form, ['states', 'medical_plans', 'divisions']);

                    setSelectedFilter(value);
                };

                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Row className='broadcast-wizard-step'>
                            <Column sm={10} className='broadcast-section broadcast-section_schedule mb-20'>
                                <Heading type={HEADING_TYPES['5']} className='broadcast-section__title'>Who would you like to send this Broadcast to?</Heading>
                                <Field name='audience' onChange={onChangeAudience}>
                                    {props => <RadioGroup {...props} options={AUDIENCE_FILTER_OPTIONS} data-testid='audience-field' isLabelInline/>}
                                </Field>
                                {isSpecificAudience && (
                                    <Row className='mt-10'>
                                        <Column sm={5} className='broadcast-section broadcast-section_schedule'>
                                            <Field name='filter' onChange={onChangeFilter}>
                                                {props => <Select {...props} options={SPECIFIC_AUDIENCE_FILTER_OPTIONS} label='Select a Filter' data-testid='filter-field'/>}
                                            </Field>
                                        </Column>
                                        <Column sm={5} className='broadcast-section broadcast-section_schedule'>
                                            {renderFilterField(form)}
                                        </Column>
                                    </Row>
                                )}
                            </Column>
                            {isSpecificAudience && (
                                <Column sm={12} className='mt-20'>
                                    <Text className='mt-20'>
                                        This broadcast will be sent to {audienceCount} eligible employees as of {today}
                                        <Tooltip className='ml-3 tooltip' content={<Text>The number of eligible employees that fall under the filters selected is subject to change by the time the Broadcast is published.</Text>}>
                                            <Icon className='tooltip__icon' type={ICON_TYPES.info} data-testid='icon-filter-delete'/>
                                        </Tooltip>
                                    </Text>
                                </Column>
                            )}
                        </Row>
                        <DynamicActionBar>
                            <Button type={BUTTON_TYPES.primary} isSubmit disabled={isDisabled} data-testid='button-wizard-continue'>
                                Continue
                            </Button>
                        </DynamicActionBar>
                    </form>
                );
            }}
        </Form>
    );
};

AudienceStep.propTypes = {
    submit: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    broadcast: PropTypes.shape({id: PropTypes.number})
};

export {AudienceStep as TestableAudienceStep};
export default withBroadcastStepCommon(AudienceStep);

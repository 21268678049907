import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Link from '@frontend/ui-kit/Components/Link';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import {resendCode} from '../../../actions/authorization';
import {getTwoStepCodeChannel} from '../../../selectors/authorization';
import {getEqual} from '../../../utils';
import {TWO_STEP_CODE_CHANNELS, ROUTES} from '../../../constants';

const ResendCodeForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const twoStepCodeChannel = useSelector(getTwoStepCodeChannel);
    const onResendCode = twoStepCodeChannel => async () => {
        const {isSuccess} = await dispatch(resendCode(twoStepCodeChannel));

        navigate(isSuccess ? ROUTES.verification : ROUTES.login);
    };

    const [isSmsChannel, isCallChannel, isEmailChannel] = [
        TWO_STEP_CODE_CHANNELS.sms,
        TWO_STEP_CODE_CHANNELS.call,
        TWO_STEP_CODE_CHANNELS.email
    ].map(getEqual(twoStepCodeChannel));

    return (
        <div className='authorization-form'>
            <div className='authorization-form-header'>
                <Heading className='authorization-form-header__title' type={HEADING_TYPES['1']}>Didn’t Get a Code?</Heading>
                <Text className='authorization-form-header__subtitle'>
                    Choose an option below for help signing in.
                </Text>
            </div>

            <div className='authorization-form-body'>
                <div className='authorization-form-action-bar'>
                    {isSmsChannel && (
                        <React.Fragment>
                            <Button className='authorization-form-action-bar__button' type={BUTTON_TYPES.secondary} onClick={onResendCode(TWO_STEP_CODE_CHANNELS.sms)}>
                                Get a New Verification Code
                            </Button>

                            <Button className='authorization-form-action-bar__button' type={BUTTON_TYPES.secondary} onClick={onResendCode(TWO_STEP_CODE_CHANNELS.call)}>
                                Get a Phone Call With a Code
                            </Button>
                        </React.Fragment>
                    )}

                    {isCallChannel && <Button className='authorization-form-action-bar__button' type={BUTTON_TYPES.secondary} onClick={onResendCode(TWO_STEP_CODE_CHANNELS.call)}>Get a Phone Call With a Code</Button>}

                    {isEmailChannel && <Button className='authorization-form-action-bar__button' type={BUTTON_TYPES.secondary} onClick={onResendCode(TWO_STEP_CODE_CHANNELS.email)}>Get an email with a Code</Button>}

                    <div className='authorization-form-action-bar__link-wrapper'>
                        Back to <Link href={ROUTES.login}>Login</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {ResendCodeForm as TestableResendCodeForm};
export default ResendCodeForm;

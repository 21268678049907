import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import DropdownWrapper, {DROPDOWN_WRAPPER_ALIGNING} from '@frontend/ui-kit/Components/DropdownWrapper';
import {signOut} from '../../../actions/authorization';
import {getIsSuperAdmin, getProfileInfo, getIsAdminPortal} from '../../../selectors/general';
import {ROUTES} from '../../../constants';
import './index.scss';

const UserInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const isAdminPortal = useSelector(getIsAdminPortal);
    const {full_name: name, role} = useSelector(getProfileInfo);
    const formattedRole = role.replace(/_/g, ' ');

    const getUserActionsList = ({onClose}) => {
        const onSignOutClick = () => {
            dispatch(signOut());
            onClose();
        };
        const onSwitchWorkspace = () => {
            navigate(isAdminPortal ? ROUTES.root : ROUTES.adminPortalRoot);
            onClose();
        };

        return (
            <div className='user-actions-list'>
                {isSuperAdmin && (
                    <div data-testid='workspace-item' className='user-actions-list__item' onClick={onSwitchWorkspace}>
                        <Icon className='user-actions-list__icon' type={ICON_TYPES.switch}/> {isAdminPortal ? 'HealthJoy Dashboard' : 'Admin Portal'}
                    </div>
                )}
                <div data-testid='sign-out-item' className='user-actions-list__item' onClick={onSignOutClick}>
                    <Icon className='user-actions-list__icon' type={ICON_TYPES.powerSignOut}/> Sign out
                </div>
            </div>
        );
    };

    const dropdownWrapperProps = {
        className: 'user-avatar',
        aligning: DROPDOWN_WRAPPER_ALIGNING.right,
        renderDropdownInitiator: () => <Icon data-testid='avatar' className='user-avatar__icon' type={ICON_TYPES.avatar}/>
    };

    return (
        <div data-testid='user-info' className='user-info'>
            <div className='user-info-profile'>
                <div>
                    <Text type={TEXT_TYPES.helperBold} className='user-info-profile__name'>{name}</Text>
                    <Text type={TEXT_TYPES.caption} className='user-info-profile__role'>{formattedRole}</Text>
                </div>

                <DropdownWrapper {...dropdownWrapperProps}>
                    {getUserActionsList}
                </DropdownWrapper>
            </div>
        </div>
    );
};

export {UserInfo as TestableUserInfo};
export default UserInfo;

import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import SimpleFileUploader from '@frontend/ui-kit/Components/FileUploader';
import {requestPrivateFileUploading, requestPublicFileUploading} from '../../../actions/general';
import {getIsSuperAdmin} from '../../../selectors/general';
import {MAX_TEXT_FILE_SIZE} from '../../../constants';

const FileUploader = ({isProtected, isDownloadable, isPrivateFile = false, maxSize = MAX_TEXT_FILE_SIZE, onChange, onBlur, fileNameReplacePattern, ...props}) => {
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const dispatch = useDispatch();

    const modifyFileName = (file, pattern) => {
        if (!pattern?.from || !pattern?.to) {
            return file;
        }

        const [basename, extension = ''] = file.name.split(/(?=\.[^.]+$)/);

        const updatedBasename = basename.replaceAll(pattern.from, pattern.to);
        const updatedName = updatedBasename + extension;

        return new File([file], updatedName, {type: file.type});
    };

    const onUpload = async file => {
        const modifiedFile = modifyFileName(file, fileNameReplacePattern);
        const uploadFunction = isPrivateFile ? requestPrivateFileUploading : requestPublicFileUploading;
        const {url, errorMessage, isSuccess} = await dispatch(uploadFunction(modifiedFile));
        const error = new Error(errorMessage);
        error.name = file.name;

        return isSuccess ? url : error;
    };

    const enhancedOnChange = value => {
        // FYI: we need to force blur manually to force field touched state (18.05.2023, Yuri);
        onBlur();
        onChange(value);
    };

    const fileUploaderProps = {
        ...props,
        isDownloadable: isProtected ? isSuperAdmin : isDownloadable,
        onUpload,
        onChange: enhancedOnChange,
        maxSize
    };

    return <SimpleFileUploader {...fileUploaderProps}/>;
};

FileUploader.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    isProtected: PropTypes.bool,
    isDownloadable: PropTypes.bool,
    isPrivateFile: PropTypes.bool,
    maxSize: PropTypes.number,
    fileNameReplacePattern: PropTypes.shape({
        from: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired
    })
};

export {FileUploader as TestableFileUploader};
export default React.memo(FileUploader);

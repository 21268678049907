import Cookies from 'js-cookie';
import {AUTHORIZE, RECEIVE_TWO_STEP_AUTHORIZATION_DATA, UNAUTHORIZE, UPDATE_DELAY_STAMP} from '../actions/actionTypes';
import {LOCAL_STORAGE_ITEMS, COOKIES_ITEMS} from '../constants';
import {normalizeBoolean} from '../utils';

export const initialState = {
    isAuthorized: !!Cookies.get(COOKIES_ITEMS.dashboardAccessToken) || !!Cookies.get(COOKIES_ITEMS.dashboardRefreshToken),
    accessToken: Cookies.get(COOKIES_ITEMS.dashboardAccessToken) || null,
    refreshToken: Cookies.get(COOKIES_ITEMS.dashboardRefreshToken) || null,
    twoStepToken: localStorage.getItem(LOCAL_STORAGE_ITEMS.twoStepToken) || null,
    twoStepCodeChannel: localStorage.getItem(LOCAL_STORAGE_ITEMS.twoStepCodeChannel) || null,
    passLength: parseInt(localStorage.getItem(LOCAL_STORAGE_ITEMS.passLength), 10) || 0,
    isTwoStepRequired: normalizeBoolean(localStorage.getItem(LOCAL_STORAGE_ITEMS.isTwoStepRequired)) ?? null
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case AUTHORIZE: {
            const {accessToken, refreshToken, isTwoStepRequired} = payload;

            return {...state, isAuthorized: true, accessToken, refreshToken, isTwoStepRequired};
        }

        case RECEIVE_TWO_STEP_AUTHORIZATION_DATA: {
            const {isTwoStepRequired, twoStepToken, twoStepCodeChannel} = payload;

            return {...state, isTwoStepRequired, twoStepToken, twoStepCodeChannel};
        }

        case UPDATE_DELAY_STAMP: {
            const {passLength} = payload;

            return {...state, passLength};
        }

        case UNAUTHORIZE: {
            return {
                ...state,
                isAuthorized: false,
                accessToken: null,
                refreshToken: null,
                twoStepToken: null,
                twoStepCodeChannel: null,
                isTwoStepRequired: null,
                passLength: 0
            };
        }

        default: {
            return state;
        }
    }
};

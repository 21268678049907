import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Tabs from '@frontend/ui-kit/Components/Tabs';
import MarketingPage from '../MarketingPage';
import PageHeader from '../../shared/PageHeader';
import {requestMarketingPageInfo} from '../../../actions/educationAndMarketing';
import {requestMarketingPages} from '../../../actions/general';
import {getMarketingPages} from '../../../selectors/general';
import {getMarketingPageInfo} from '../../../selectors/educationAndMarketing';
import {getEqual, getItemKeyValue} from '../../../utils';
import {ROUTES} from '../../../constants';

const FALLBACK_PAGE_INDEX = 0;

const MarketingMaterials = () => {
    const dispatch = useDispatch();
    const [isPageInfoLoaded, setIsPageInfoLoaded] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const pages = useSelector(getMarketingPages);
    const pageInfo = useSelector(getMarketingPageInfo);

    const onChangeTab = activeTab => {
        const {id} = pages[activeTab];

        requestMarketingPageInfo(id);
        navigate(`${ROUTES.educationAndMarketing}/${id}`);
    };

    useEffect(() => {
        (async () => {
            const {pages} = await dispatch(requestMarketingPages()); // FYI: this request needs to get actual data due to the prevention of redirection to the removed page (15.11.20, Pasha)
            const activePageId = pages.find(getEqual(parseInt(params.id, 10), 'id'))?.id;

            if (!activePageId) {
                const {id: fallbackPageId} = pages[FALLBACK_PAGE_INDEX] || {};
                const fallbackPageRoute = fallbackPageId ? `${ROUTES.educationAndMarketing}/${fallbackPageId}` : ROUTES.pageNotFound;
                return navigate(fallbackPageRoute);
            }

            await dispatch(requestMarketingPageInfo(activePageId));
            setIsPageInfoLoaded(true);
        })();
    }, [dispatch, params.id]);

    const tabs = pages.map(getItemKeyValue('title'));
    const activeTab = pages.findIndex(getEqual(pageInfo.id, 'id'));

    return (
        <div className='marketing-materials'>
            <PageHeader title='Education & Marketing Materials'/>
            <Tabs tabs={tabs} onChange={onChangeTab} activeTab={activeTab}/>

            {isPageInfoLoaded && <MarketingPage {...pageInfo}/>}
        </div>
    );
};

export {MarketingMaterials as TestableMarketingMaterials};
export default MarketingMaterials;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {setRedirectToUrl, resetRedirectToUrl} from '../../actions/general';
import {getAuthorizationStatus} from '../../selectors/authorization';
import {getRedirectToUrl} from '../../selectors/general';
import {trackLocationChange} from '../../middlewares/analyticsTracker';
import {parseQuery} from '../../utils';
import {ROUTES} from '../../constants';

const withAuthorizationControl = ({isAuthorizationPage}) => Component => {
    return props => {
        const [isReady, setIsReady] = useState(false);
        const isAuthorized = useSelector(getAuthorizationStatus);
        const redirectToUrl = useSelector(getRedirectToUrl);
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const location = useLocation();
        const state = useSelector(state => state);

        useEffect(() => {
            // FYI: Tracking ANALYTICS_EVENT_NAMES.pageLoad event. Will refactor it when updating analytic flow (02.13.2025, Pasha)
            trackLocationChange({payload: {location}}, state);
        }, [location]);

        useEffect(() => {
            const {return_to: urlToRedirect} = parseQuery(location.search);
            const isUnavailable = isAuthorizationPage ? isAuthorized : !isAuthorized;
            if (!isAuthorized && urlToRedirect) {
                dispatch(setRedirectToUrl(urlToRedirect));
            }

            if (isAuthorized && redirectToUrl) {
                dispatch(resetRedirectToUrl());
                window.location.replace(redirectToUrl);
            }

            if (isUnavailable) {
                navigate(ROUTES.root);
            }

            setIsReady(true);
        }, [dispatch, isAuthorized, redirectToUrl]);

        return isReady && <Component {...props}/>;
    };
};

export {withAuthorizationControl as testableWithAuthorizationControl};
/* istanbul ignore next */
export default withAuthorizationControl;

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text from '@frontend/ui-kit/Components/Text';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import DateTimePicker from '@frontend/ui-kit/Components/DateTimePicker';
import Select from '@frontend/ui-kit/Components/Select';
import usePrevious from '../../../hooks/usePrevious';
import DynamicActionBar from '../../shared/DynamicActionBar';
import {Form, Field} from '../../shared/FormComponents';
import withBroadcastStepCommon from '../../../HOC/withBroadcastStepCommon';
import {requestBroadcasts} from '../../../actions/broadcaster';
import {getBroadcasts} from '../../../selectors/broadcaster';
import {
    pipe,
    equal,
    getEqual,
    negate,
    isEmpty,
    isDateWithinRange,
    validateRequired,
    getItemKeyValue,
    addYears,
    getTimeZoneOffset,
    removeOffset
} from '../../../utils';
import {FORMS, ROUTES, FAMILY_SEGMENTS, BROADCAST_STATUSES} from '../../../constants';
import {BROADCAST_USA_TIMEZONES_OPTIONS} from '../../../options';
import analyticsService from '../../../analyticsService';
import './index.scss';

const RECOMMENDED_DELIVERY_TIME = {from: '06:00', to: '21:00'};

const RELATIONS_BY_FAMILY_SEGMENT = {
    [FAMILY_SEGMENTS.primary]: ['primary'],
    [FAMILY_SEGMENTS.primarySpouse]: ['primary', 'spouse']
};

const MAX_DATE = addYears(new Date(), 1);
const MIN_DATE = new Date();

/* istanbul ignore next */
const validate = values => {
    return {
        timezone: validateRequired(values.timezone),
        scheduled_for: validateRequired(values.scheduled_for)
    };
};

const InformationStep = ({onGoBack, submit, broadcast}) => {
    const navigate = useNavigate();
    const {conditions} = broadcast;
    const {state} = conditions;
    const dispatch = useDispatch();
    const broadcasts = useSelector(getBroadcasts);
    const prevScheduledFor = usePrevious(broadcast?.scheduled_for);
    const schduleButtonText = 'Schedule';

    useEffect(() => {
        const [prevDeliveryDate, deliveryDate] = [prevScheduledFor, broadcast?.scheduled_for].map(value => value?.split('T')?.[0]);

        if (!deliveryDate || equal(prevDeliveryDate, deliveryDate)) {
            return;
        }

        dispatch(requestBroadcasts({scheduledFor: deliveryDate, status: BROADCAST_STATUSES.scheduled}));
    }, [prevScheduledFor, broadcast, dispatch]);

    const onSubmit = values => {
        analyticsService.trackClicked(schduleButtonText, {broadcast_id: broadcast?.id});
        const relationIn = {relations: RELATIONS_BY_FAMILY_SEGMENT[FAMILY_SEGMENTS.primary]};
        const scheduledWithTimeZone = `${values.scheduled_for}-${values.timezone}`;

        const enhancedValues = {
            ...broadcast,
            scheduled_for: scheduledWithTimeZone,
            status: BROADCAST_STATUSES.scheduled,
            condition_templates_arguments: {...broadcast.condition_templates_arguments, 'relation-in': relationIn}
        };

        return submit(enhancedValues, null, state?.$in);
    };

    const onBackToContent = ({target}) => {
        analyticsService.trackClicked(target.textContent, {broadcast_id: broadcast?.id});
        onGoBack();
    };

    const onSubmitSuccess = () => navigate(`${ROUTES.broadcastDetails}/${broadcast?.id}`);

    const getFieldWarning = ({message}) => <div key={message} className='field-warning'><Icon className='field-warning__icon' type={ICON_TYPES.alert}/>{message}</div>;

    const scheduledFor = broadcast?.scheduled_for ? removeOffset(broadcast.scheduled_for) : broadcast.scheduled_for;
    const timeZone = broadcast?.scheduled_for ? getTimeZoneOffset(broadcast.scheduled_for) : null;
    const processableBroadcasts = broadcasts.filter(pipe(getEqual(broadcast?.id, 'id'), negate));
    const [recommendedDeliveryDateFrom, recommendedDeliveryDateTo] = [
        RECOMMENDED_DELIVERY_TIME.from,
        RECOMMENDED_DELIVERY_TIME.to
    ].map(time => scheduledFor?.replace(/T.*/g, `T${time}`));
    const deliveryTimeFieldWarnings = [
        {isHidden: isEmpty(processableBroadcasts), message: 'We recommend only sending one broadcast per day.'},
        {isHidden: isDateWithinRange(scheduledFor, recommendedDeliveryDateFrom, recommendedDeliveryDateTo), message: 'We recommend sending broadcasts between the hours of 6am and 9pm.'}
    ].filter(pipe(getItemKeyValue('isHidden'), negate));

    return (
        <Form name={FORMS.broadcastContentStep}
            initialValues={{
                scheduled_for: scheduledFor,
                timezone: timeZone
            }}
            onSubmit={onSubmit}
            validate={validate}
            onSubmitSuccess={onSubmitSuccess}>
            {({handleSubmit, valid}) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Row className='broadcast-wizard-step'>
                        <Column sm={6} className='broadcast-section broadcast-section_schedule'>
                            <Heading type={HEADING_TYPES['4']} className='broadcast-section__title'>Delivery Date & Time</Heading>

                            <div className='broadcast-section__label'>
                                <Field name='timezone'>
                                    {props => <Select {...props} options={BROADCAST_USA_TIMEZONES_OPTIONS} label='Timezone' placeholder='Select a timezone' isRequired/>}
                                </Field>
                            </div>

                            <Text className='mt-8 ml-8'>All Broadcasts will be sent in the timezone selected regardless of recipient location.</Text>

                            <div className='broadcast-section__label mt-8'>
                                <Field name='scheduled_for'>
                                    {props => <DateTimePicker {...props} placeholder='mm/dd/yyyy hh:mm aa' minDate={MIN_DATE} maxDate={MAX_DATE} label='Delivery Time' isRequired/>}
                                </Field>

                                {scheduledFor && deliveryTimeFieldWarnings.map(getFieldWarning)}
                            </div>
                        </Column>
                    </Row>

                    <DynamicActionBar>
                        <Button onClick={onBackToContent} type={BUTTON_TYPES.secondary}>Back to Content</Button>
                        <Button disabled={!valid} type={BUTTON_TYPES.primary} isSubmit>{schduleButtonText}</Button>
                    </DynamicActionBar>
                </form>
            )}
        </Form>
    );
};

InformationStep.propTypes = {
    submit: PropTypes.func.isRequired,
    onGoBack: PropTypes.func.isRequired,
    broadcast: PropTypes.shape({id: PropTypes.number}),
    conditions: PropTypes.shape(PropTypes.arrayOf(PropTypes.string)),
    $in: PropTypes.arrayOf(PropTypes.string)
};

export {InformationStep as TestableInformationStep};
export default withBroadcastStepCommon(InformationStep);

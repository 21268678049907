export const getBroadcasts = state => state.broadcaster.broadcasts;

export const getBroadcastTemplates = state => state.broadcaster.broadcastTemplates;

export const getActiveBroadcastsTab = state => state.broadcaster.activeTab;

export const getBroadcast = state => state.broadcaster.broadcast;

export const getBroadcastTemplate = state => state.broadcaster.broadcastTemplate;

export const getWalletLinks = state => state.broadcaster.walletLinks;

export const getBroadcastAudience = state => state.broadcaster.broadcastAudience;

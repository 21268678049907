import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Sticker, {STICKER_TYPES} from '@frontend/ui-kit/Components/Sticker';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import PlanCopyPopup from '../PlanCopyPopup';
import PlanCopyRxFromMedicalPopup from '../PlanCopyRxFromMedicalPopup';
import RenewalPlanInfoAlert from '../RenewalPlanInfoAlert';
import CardAvailabilityConditionPopup from '../../shared/CardAvailabilityConditionPopup';
import CategoryAndTrackingManagementPopup from '../../shared/CategoryAndTrackingManagementPopup';
import ActionButton from '../../../Containers/shared/ActionButton';
import CustomizablePopup from '../../shared/CustomizablePopup';
import SuccessfulPlanCopyingAlert from '../SuccessfulPlanCopyingAlert';
import withPopup from '../../../HOC/withPopup';
import useForm from '../../../hooks/useForm';
import useFormState from '../../../hooks/useFormState';
import {getPlanPeriodTypeCriteria, getIsSuperAdmin} from '../../../selectors/general';
import {getIsCopyingEnabled} from '../../../selectors/benefits';
import {PLAN_PERIOD_TYPES, ROUTES, ANCILLARY_PLAN_CATEGORIES, ANCILLARY_PLANS_DATA_BY_CATEGORY} from '../../../constants';
import {getEqual, equal} from '../../../utils';
import './index.scss';

const POPUP_ID = 'benefitsEnableCustomizationPopup';

const PlanFormHeader = props => {
    const {
        planId,
        planType,
        planCategory,
        title,
        isReadonly,
        isCorePlan,
        isCustomizedMode,
        isCustomizable = false,
        isAvailabilityConditionShowed,
        isDeactivated = false,
        openPopup,
        closePopup,
        setCustomizedMode,
        requestConditionalVariablesSetting,
        copyPlan,
        copyPlanRxFromMedical,
        onSubmit = () => {}
    } = props;
    const navigate = useNavigate();
    const form = useForm();
    const {values} = useFormState();
    const {planPeriodId} = useParams();
    const [copiedPlanName, setCopiedPlanName] = useState('');
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const isCopyingEnabled = useSelector(getIsCopyingEnabled);
    const isRenewalPlanPeriod = useSelector(state => getPlanPeriodTypeCriteria(state, planPeriodId, PLAN_PERIOD_TYPES.renewal));

    const isRxPlan = equal(planCategory, ANCILLARY_PLAN_CATEGORIES.prescription_program);

    const isEnableCustomizationShowed = !isCustomizedMode && isCustomizable;
    const isCopyPlanShowed = (planCategory || planType) && !isRxPlan;

    const onOpenEnableCustomizationPopup = () => {
        const content = `
            Are you sure you want to customize this card? After saving, you will not be able to return to the simplified card format.
            Wallet card customization will allow you to add card sections, reorder sections, and format the text.
        `;
        const onContinue = async () => {
            const submissionErrors = await onSubmit();
            closePopup();

            if (!submissionErrors) {
                setCustomizedMode(true);
            }
        };

        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>Cancel</Button>
                <Button onClick={onContinue}>Continue</Button>
            </React.Fragment>
        );
        const popupProps = {title: 'Enable Customization', content, actionBar};
        const children = <CustomizablePopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onOpenCardAvailabilityConditionPopup = () => {
        const onSave = cardView => {
            form.change('card_view', cardView);
            closePopup();
        };
        const popupProps = {cardView: values.card_view, planId, onCancel: closePopup, requestConditionalVariablesSetting, onSave};
        const children = <CardAvailabilityConditionPopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onOpenPlanCopyPopup = () => {
        const onSave = (planDetails, isCopyingAcrossDifferentCompanies) => {
            const copiedPlanName = (isCorePlan && planDetails.name)
                ? planDetails.name.replace('Copy - ', '')
                : ANCILLARY_PLANS_DATA_BY_CATEGORY[planCategory]?.label;

            setCopiedPlanName(copiedPlanName);
            copyPlan(planDetails, isCopyingAcrossDifferentCompanies);
        };
        const popupProps = {planId, planType, planCategory, isCorePlan, planPeriodId, onClose: closePopup, onSave};
        const children = <PlanCopyPopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onOpenCategoryAndTrackingManagementPopup = () => {
        const onSave = cardView => {
            form.change('card_view', cardView);
            closePopup();
        };
        const popupProps = {cardView: values.card_view, planId, onCancel: closePopup, onSave};
        const children = <CategoryAndTrackingManagementPopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onOpenPlanCopyRxFromMedicalPopup = () => {
        const onSave = medicalPlanDetails => {
            setCopiedPlanName(medicalPlanDetails.name);
            copyPlanRxFromMedical(medicalPlanDetails);
        };
        const popupProps = {planPeriodId, onClose: closePopup, onSave};
        const children = <PlanCopyRxFromMedicalPopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const actionButtonOptions = [
        {label: 'Visit Plan Manager', action: () => navigate(`${ROUTES.planManager}/${planId}`), visible: isSuperAdmin && isCorePlan},
        {label: 'Enable customization', action: onOpenEnableCustomizationPopup, visible: isEnableCustomizationShowed},
        {label: 'Set card’s availability conditions', action: onOpenCardAvailabilityConditionPopup, visible: isSuperAdmin && isAvailabilityConditionShowed},
        {label: 'Copy Data From Another Plan', action: onOpenPlanCopyPopup, visible: isCopyPlanShowed},
        {label: 'Steerage and Tracking management', action: onOpenCategoryAndTrackingManagementPopup, visible: isSuperAdmin && !isCorePlan && !!planCategory},
        {label: 'Copy Rx Data From Medical Plan', action: onOpenPlanCopyRxFromMedicalPopup, visible: isCustomizedMode && isRxPlan}
    ].filter(getEqual(true, 'visible'));

    return (
        <div className='plan-form-header'>
            <div className='title-wrapper'>
                <Text className='title-wrapper__text' type={TEXT_TYPES.bodyBold}>
                    {title} {isDeactivated && <Sticker type={STICKER_TYPES.default} className='title-wrapper__sticker'>Deactivated</Sticker>}
                </Text>

                {!isReadonly && !!actionButtonOptions.length && <ActionButton className='title-wrapper__button' options={actionButtonOptions}>Actions</ActionButton>}
            </div>

            {isCopyingEnabled && <SuccessfulPlanCopyingAlert className='plan-form-header__alert' copiedPlanName={copiedPlanName}/>}
            {isRenewalPlanPeriod && <RenewalPlanInfoAlert className='plan-form-header__alert'/>}
        </div>
    );
};

PlanFormHeader.propTypes = {
    title: PropTypes.string.isRequired,
    planId: PropTypes.number,
    planType: PropTypes.string,
    planCategory: PropTypes.string,
    isReadonly: PropTypes.bool,
    isCustomizedMode: PropTypes.bool,
    isCorePlan: PropTypes.bool,
    isAvailabilityConditionShowed: PropTypes.bool,
    isCustomizable: PropTypes.bool,
    isDeactivated: PropTypes.bool,
    onSubmit: PropTypes.func,
    copyPlan: PropTypes.func,
    copyPlanRxFromMedical: PropTypes.func,
    setCustomizedMode: PropTypes.func,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func,
    requestConditionalVariablesSetting: PropTypes.func
};

export {PlanFormHeader as TestablePlanFormHeader};
export default withPopup(POPUP_ID)(PlanFormHeader);

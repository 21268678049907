import Cookies from 'js-cookie';
import {AUTHORIZE, RECEIVE_TWO_STEP_AUTHORIZATION_DATA, RECOVER_PASSWORD, UNAUTHORIZE, UPDATE_DELAY_STAMP, AUTHORIZATION_FAILED} from './actionTypes';
import apiServices from '../apiServices';
import {getAccessToken, getRefreshToken, getTwoStepToken} from '../selectors/authorization';
import {getActiveCompany} from '../selectors/general';
import {
    ROUTES,
    LOCAL_STORAGE_ITEMS,
    AUTHORIZATION_ERROR_MESSAGES_BY_REASON,
    MS_IN_SEC,
    COOKIES_ITEMS
} from '../constants';
import {secToMin, decodeJWT} from '../utils';

// TODO: deal with passLength (1.12.2020, Oleh);
const authorize = authorizationData => ({type: AUTHORIZE, ...authorizationData});

const unauthorize = () => ({type: UNAUTHORIZE});

const recoverPassword = () => ({type: RECOVER_PASSWORD});

export const failAuthorization = reason => ({type: AUTHORIZATION_FAILED, reason});

export const updateLogoutParams = passLength => ({type: UPDATE_DELAY_STAMP, passLength});

const receiveTwoStepAuthorizationData = twoStepAuthorizationData => ({type: RECEIVE_TWO_STEP_AUTHORIZATION_DATA, ...twoStepAuthorizationData});

export const setTwoStepAuthorizationData = twoStepAuthorizationData => dispatch => {
    Object.entries(twoStepAuthorizationData).forEach(([item, value]) => localStorage.setItem(item, value));

    dispatch(receiveTwoStepAuthorizationData(twoStepAuthorizationData));
};

export const setAuthorizationData = authorizationData => dispatch => {
    const {accessToken, refreshToken} = authorizationData;
    const passLength = localStorage.getItem(LOCAL_STORAGE_ITEMS.passLength) || null;
    const cookiesAttributes = {path: ROUTES.root, domain: window.location.hostname};
    // FYI: Should get the correct expiration date from the token (Pasha, 20.05.2022)
    const [accessTokenExpiryDate, refreshTokenExpiryDate] = [accessToken, refreshToken].map(token => {
        const {exp: expiredToken} = decodeJWT(token) || {};

        return new Date(expiredToken * MS_IN_SEC);
    });

    // FYI: access_token must be shared to all 'healthjoy.com' domains (Pasha, 13.05.2022)
    Cookies.set(COOKIES_ITEMS.accessToken, accessToken, {...cookiesAttributes, domain: window.location.hostname.split('.').slice(1).join('.'), expires: accessTokenExpiryDate});
    // FYI: access_token_cookie and refresh_token_cookie are used for zendesk auth external requests for dashboard backend (Pasha, 13.05.2022)
    Cookies.set(COOKIES_ITEMS.dashboardAccessToken, accessToken, {...cookiesAttributes, expires: accessTokenExpiryDate});
    Cookies.set(COOKIES_ITEMS.dashboardRefreshToken, refreshToken, {...cookiesAttributes, expires: refreshTokenExpiryDate});

    dispatch(updateLogoutParams(passLength));
    dispatch(authorize(authorizationData));
};

const removeAuthorizationData = () => dispatch => {
    const removableItems = ['twoStepToken', 'twoStepCodeChannel', 'passLength', 'isTwoStepRequired'];
    const cookiesAttributes = {path: ROUTES.root, domain: window.location.hostname};

    removableItems.forEach(item => localStorage.removeItem(item));
    Cookies.remove(COOKIES_ITEMS.accessToken, {...cookiesAttributes, domain: window.location.hostname.split('.').slice(1).join('.')});
    [COOKIES_ITEMS.dashboardAccessToken, COOKIES_ITEMS.dashboardRefreshToken].forEach(token => Cookies.remove(token, cookiesAttributes));

    dispatch(unauthorize());
};

export const requestUserAuthData = () => async dispatch => {
    const {data: authData, isSuccess} = await apiServices.getUserAuthData();

    if (isSuccess) {
        const {dashboard_password_min_length: passLength} = authData;

        localStorage.setItem(LOCAL_STORAGE_ITEMS.passLength, passLength);
        dispatch(updateLogoutParams(passLength));
    }

    return {isSuccess, authData};
};

export const login = (username, password) => async () => {
    const {data: authData, isSuccess} = await apiServices.login({username, password});
    const {reason, attempts_left: attemptsLeft} = authData;
    const submissionGlobalError = AUTHORIZATION_ERROR_MESSAGES_BY_REASON[reason];

    return {
        isSuccess,
        authData,
        reason,
        submissionGlobalError: attemptsLeft ? `${submissionGlobalError} Attempts left: ${attemptsLeft}.` : submissionGlobalError
    };
};

export const verifyCode = twoStepCode => async (dispatch, getState) => {
    const state = getState();
    const currTwoStepToken = getTwoStepToken(state);

    const {data: authData, isSuccess} = await apiServices.verifyCode({twoStepToken: currTwoStepToken, twoStepCode});
    const submissionGlobalError = AUTHORIZATION_ERROR_MESSAGES_BY_REASON[authData.reason];

    return {isSuccess, authData, submissionGlobalError};
};

export const resendCode = twoStepCodeChannel => async (dispatch, getState) => {
    const state = getState();
    const twoStepToken = getTwoStepToken(state);
    const {isSuccess} = await apiServices.resendCode({twoStepToken, twoStepCodeChannel});

    return {isSuccess};
};

export const signOut = () => async (dispatch, getState) => {
    const state = getState();
    const accessToken = getAccessToken(state);
    const refreshToken = getRefreshToken(state);

    try {
        await apiServices.signOut({accessToken, refreshToken});
    } catch (e) {
        console.error(`signOut error: ${e}`);
    }

    dispatch(removeAuthorizationData());
};

export const handleTokenRefresh = tokenData => dispatch => {
    const {access_token: accessToken, refresh_token: refreshToken, two_step_is_required: isTwoStepRequired} = tokenData;

    dispatch(setAuthorizationData({accessToken, refreshToken, isTwoStepRequired}));
};

export const requestGrantTokenChecking = (userId, token) => async () => {
    const {isSuccess} = await apiServices.checkPasswordGrantToken({userId, token});

    return {isSuccess};
};

export const requestPasswordSetting = (userId, token, password) => async () => {
    const {data, isSuccess} = await apiServices.setPassword({userId, token, password});

    return {isSuccess, submissionGlobalError: data.message};
};

export const requestPasswordRecovering = ({username}) => async dispatch => {
    const {data, isSuccess} = await apiServices.recoverPassword({username});

    dispatch(recoverPassword());

    return {isSuccess, submissionErrors: {username: data.message}};
};

export const requestAuthInfo = companyAlias => async () => {
    const {data, isSuccess} = await apiServices.getAuthInfo({companyAlias});

    const authInfo = {
        ...data,
        dashboard_session_ttl: secToMin(data.dashboard_session_ttl),
        mobile_session_ttl: secToMin(data.mobile_session_ttl)
    };

    return {isSuccess, data: authInfo};
};

export const requestAuthInfoUpdating = authInfo => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.updateAuthInfo({companyAlias, authInfo});

    return {data, isSuccess, submissionErrors: data.messages};
};

export const requestZendeskChat = () => async () => {
    const {data, isSuccess} = await apiServices.getZendeskChat();

    return {data, isSuccess};
};

export const requestZendeskHelpCenter = () => async () => {
    const {data, isSuccess} = await apiServices.getZendeskHelpCenter();

    return {data, isSuccess};
};

export const requestZendeskSSO = () => async () => {
    const {data, isSuccess} = await apiServices.getZendeskSSO();

    return {data, isSuccess};
};

export const requestZendeskProfileSSO = () => async () => {
    const {data, isSuccess} = await apiServices.getZendeskProfileSSO();

    return {data, isSuccess};
};

import React from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';

const ActionsCell = ({row: {index}}) => {
    const navigate = useNavigate();
    const {search, pathname} = useLocation();
    const onGoToErrorDetails = () => navigate(`${pathname}/${index}${search}`);

    return (
        <Button data-testid='view-button' onClick={onGoToErrorDetails} type={BUTTON_TYPES.tertiary}>
            View
            <Icon type={ICON_TYPES.arrowRight} className='ml-2'/>
        </Button>
    );
};

ActionsCell.propTypes = {
    row: PropTypes.shape({
        index: PropTypes.number
    })
};

export {ActionsCell as TestableActionsCell};
export default ActionsCell;

import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Wizard from '../../../Containers/shared/Wizard';
import GeneralInfoStep from '../CompanyInfoWizardSteps/GeneralInfoStep';
import HealthPlanStep from '../CompanyInfoWizardSteps/HealthPlanStep';
import HealthjoyOptionsStep from '../CompanyInfoWizardSteps/HealthjoyOptionsStep';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {requestCompany, requestCompanyUpdating} from '../../../actions/company';
import {getIsSuperAdmin, getProfilePermissions} from '../../../selectors/general';
import {ROUTES} from '../../../constants';

const WIZARD_STEPS = [
    {StepComponent: GeneralInfoStep, stepName: 'general_info', stepTitle: 'General Information'},
    {StepComponent: HealthPlanStep, stepName: 'health_plan', stepTitle: 'Health Plan'},
    {StepComponent: HealthjoyOptionsStep, stepName: 'healthjoy_options', stepTitle: 'Healthjoy'}
];

const CompanyInfoWizard = ({onComplete}) => {
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const profilePermissions = useSelector(getProfilePermissions);
    const navigate = useNavigate();

    const wizardProps = {
        steps: WIZARD_STEPS,
        requestCompany,
        requestCompanyUpdating,
        onSuccess: profilePermissions.timeline_edit ? onComplete : () => navigate(ROUTES.root),
        isReadonly: !profilePermissions.company_info_edit,
        isStepSkippable: isSuperAdmin
    };

    return <Wizard {...wizardProps}/>;
};

CompanyInfoWizard.propTypes = {
    onComplete: PropTypes.func.isRequired,
    requestCompany: PropTypes.func,
    requestCompanyUpdating: PropTypes.func
};

export {CompanyInfoWizard as TestableCompanyInfoWizard};
export default withSubStepCompletion(CompanyInfoWizard);

import {
    addDays,
    equal,
    formatListByLocales,
    generateUniqueId,
    getEncodedHtml,
    getEqual,
    validateJSON,
    validateJSONObject
} from './utils';
import {
    ROUTES,
    SFTP_HOST_TYPES,
    SFTP_SETTINGS_TYPES,
    PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES,
    PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS,
    LOCAL_STORAGE_ITEMS,
    ANCILLARY_PLANS_DATA_BY_CATEGORY,
    IMPORT_CONFIG_TYPES,
    ELIGIBILITY_IMPORT_MASS_MEMBERS_LABELS,
    TPA_GROUP_TWEAK_JSON_FIELDS,
    TPA_GROUP_TWEAK_JSON_OBJECT_FIELDS
} from './constants';
import benefitsCoreOverviewThumbnail from './static/images/thumbnails/benefits-core-overview.svg';
import benefitsCoreDetailsThumbnail from './static/images/thumbnails/benefits-core-details.svg';
import benefitsAncillaryOverviewThumbnail from './static/images/thumbnails/benefits-ancillary-overview.svg';
import benefitsAncillaryDetailsThumbnail from './static/images/thumbnails/benefits-ancillary-details.svg';
import eligibilityOverviewThumbnail from './static/images/thumbnails/eligibility-overview.svg';
import eligibilityDetailsThumbnail from './static/images/thumbnails/eligibility-details.svg';
import timelineThumbnail from './static/images/thumbnails/timeline.svg';
import companyInformationThumbnail from './static/images/thumbnails/company-information.svg';
import reportingThumbnail from './static/images/thumbnails/reporting.svg';
import educationMarketingThumbnail from './static/images/thumbnails/education-marketing.svg';
import bookOfBusinessAnnouncement from './static/images/reportingAnnouncement/book_of_business.svg';
import benefitsUsageAnnouncement from './static/images/reportingAnnouncement/benefits_usage.svg';
import costContainmentAnnouncement from './static/images/reportingAnnouncement/cost_containment.svg';
import completeRoiAnnouncement from './static/images/reportingAnnouncement/complete_roi.svg';

export const getOnboardingInfo = ({routesUnavailabilityMap}) => {
    const {isUnavailable: isBookOfBusinessReportingUnavailable} = routesUnavailabilityMap[ROUTES.bookOfBusinessReporting];

    const reportingDescription = (
        <span>
            <b>{isBookOfBusinessReportingUnavailable ? 'Three' : 'Four'} new views</b> with the ability to filter results for deeper
            insights and compare performance with other HealthJoy clients.
        </span>
    );
    const reportingInfo = {
        type: 'reporting',
        isNew: true,
        isSkipOnLoad: true,
        slides: [
            isBookOfBusinessReportingUnavailable ? null : <img src={bookOfBusinessAnnouncement} alt=''/>,
            <img src={benefitsUsageAnnouncement} alt=''/>,
            <img src={costContainmentAnnouncement} alt=''/>,
            <img src={completeRoiAnnouncement} alt=''/>
        ].filter(Boolean),
        tooltipTitle: '🎉 New: Data Insights Dashboard!',
        tooltipDescription: reportingDescription,
        popupTitle: <span>New and improved reporting:<br/>Data Insights Dashboard!</span>,
        popupDescription: reportingDescription,
        thumbnail: reportingThumbnail
    };

    return {
        [`${ROUTES.benefits}/:planPeriodId/core_benefits`]: {
            type: 'benefits_core_overview',
            videoId: '9r20Mc92NmU',
            tooltipTitle: 'Benefits Overview',
            tooltipDescription: 'Provide an overview of your core and ancillary benefits so we can start building out your HealthJoy instance.',
            thumbnail: benefitsCoreOverviewThumbnail
        },
        [`${ROUTES.benefits}/:planPeriodId/core_benefits_details`]: {
            type: 'benefits_core_details',
            videoId: 'aeVwz73S9lY',
            tooltipTitle: 'Benefits Overview',
            tooltipDescription: 'Our concierge team needs details and documents about each of your benefits to appropriately support your employees.',
            thumbnail: benefitsCoreDetailsThumbnail
        },
        [`${ROUTES.benefits}/:planPeriodId/ancillary_benefits`]: {
            type: 'benefits_ancillary_overview',
            videoId: 'QXJE-ZC_ers',
            tooltipTitle: 'Benefits Overview',
            tooltipDescription: 'Provide an overview of your core and ancillary benefits so we can start building out your HealthJoy instance.',
            thumbnail: benefitsAncillaryOverviewThumbnail
        },
        [`${ROUTES.benefits}/:planPeriodId/ancillary_benefits_details`]: {
            type: 'benefits_ancillary_details',
            videoId: '_0Iv_mohnlk',
            tooltipTitle: 'Benefits Overview',
            tooltipDescription: 'HealthJoy\'s concierge team needs details and documents about each of your benefits to appropriately support your employees.',
            thumbnail: benefitsAncillaryDetailsThumbnail
        },
        [`${ROUTES.eligibility}/:planPeriodId/implementation`]: {
            type: 'eligibility_overview',
            videoId: 'O09paSYYqQc',
            tooltipTitle: 'Eligibility',
            tooltipDescription: 'Provide us with some details about how you manage eligibility. We\'ll use this to personalize your employees\' app experience.',
            thumbnail: eligibilityOverviewThumbnail
        },
        [`${ROUTES.eligibility}/:planPeriodId/details`]: {
            type: 'eligibility_details',
            videoId: 'YoV8nqPftmY',
            tooltipTitle: 'Eligibility',
            tooltipDescription: 'Provide us with some details about how you manage eligibility. We\'ll use this to personalize your employees\' app experience.',
            thumbnail: eligibilityDetailsThumbnail
        },
        [ROUTES.timeline]: {
            type: 'timeline',
            videoId: 'vLv7GZ7-bpY',
            tooltipTitle: 'Timeline',
            tooltipDescription: 'Learn what we need by when in order to meet your target launch date.',
            thumbnail: timelineThumbnail
        },
        [ROUTES.timelineGeneration]: {
            type: 'timeline',
            videoId: 'vLv7GZ7-bpY',
            tooltipTitle: 'Timeline',
            tooltipDescription: 'Learn what we need by when in order to meet your target launch date.',
            thumbnail: timelineThumbnail
        },
        // [ROUTES.contacts]: {
        //     type: 'contacts',
        //     videoId: 'atfGosMcpno',
        //     tooltipTitle: 'Your Contacts',
        //     tooltipDescription: 'Invite others to manage and contribute to your dashboard instance.',
        //     thumbnail: contactsThumbnail
        // },
        [ROUTES.companyInfo]: {
            type: 'company_information',
            videoId: 'atfGosMcpno',
            tooltipTitle: 'Company Info',
            tooltipDescription: 'Gain insight into how your employees are using HealthJoy.',
            thumbnail: companyInformationThumbnail
        },
        [ROUTES.bookOfBusinessReporting]: reportingInfo,
        [ROUTES.costContainmentReporting]: reportingInfo,
        [`${ROUTES.benefitsUsageReporting}/:type?`]: reportingInfo,
        [ROUTES.completeRoiReporting]: reportingInfo,
        // [ROUTES.broadcasterWelcome]: {
        //     type: 'broadcaster',
        //     videoId: '45OkNGneogI',
        //     tooltipTitle: 'Broadcaster',
        //     tooltipDescription: 'Send in-app messages to employees through the HealthJoy app notifying them about open enrollment, a new wellness initiative, and much more.',
        //     thumbnail: broadcasterThumbnail,
        //     isSkipOnLoad: true
        // },
        [`${ROUTES.educationAndMarketing}/:id`]: {
            type: 'education_marketing',
            videoId: 'wUAbW6nsVaA',
            tooltipTitle: 'Marketing Materials',
            tooltipDescription: 'Gain insight into how your employees are using HealthJoy.',
            thumbnail: educationMarketingThumbnail
        }
    };
};

export const getTimelineStepsWithUrl = planPeriodId => {
    const steps = {
        // Renewal steps
        ren_renewal_overview: {
            url: '/renewal_overview',
            title: 'Renewal Overview',
            description: 'Review your open enrollment and plan overview information and update anything that is changing for the upcoming plan year. If you\'d like to download a walkthrough of the renewal process, download the <a href="https://cdn.healthjoy.com/public/Dashboard_V2_Renewal_Guide_1.pdf" target="_blank" download class="link">HealthJoy Renewal Guide</a>. If you would like a refresher on HealthJoy, please visit the Education & Marketing section or reach out to your Customer Success contact.'
        },
        ren_gathering_benefits: {
            url: '/gathering_benefits',
            title: 'Information Gathering - Benefits',
            description: 'Update and provide new detailed benefits information so that we can service your employees and families effectively at renewal. This includes uploading new medical plan documents and making any desired changes to the benefits wallet.'
        },
        ren_gathering_eligibility: {
            url: '/gathering_eligibility',
            title: 'Information Gathering - Eligibility',
            description: 'Upload census and other applicable files to ensure that everyone who is eligible for HealthJoy sees their updated benefits elections at renewal.'
        },
        ren_benefits_review: {
            title: 'Benefits Review',
            url: '/benefits_review',
            description: 'Review each Benefits Wallet card and Medical Plan Summary in Benefits Preview. <span class="editing-advice">Click the circles below to approve each section.</span>'
        },
        ren_overview_open_enrollment: {url: '/overview/open_enrollment', title: 'Open Enrollment'},
        ren_benefits_core_benefits1: {url: `/benefits/${planPeriodId}/core_benefits`, title: 'Core Benefits'},
        ren_benefits_ancillary_benefits1: {url: `/benefits/${planPeriodId}/ancillary_benefits`, title: 'Ancillary Benefits'},
        ren_eligibility_implementation1: {url: `/eligibility/${planPeriodId}/implementation`, title: 'Eligibility'},
        ren_benefits_core_benefits2: {url: `/benefits/${planPeriodId}/core_benefits`, title: 'Core Overview'},
        ren_benefits_core_benefits_details: {url: `/benefits/${planPeriodId}/core_benefits_details`, title: 'Core Details'},
        ren_benefits_ancillary_benefits2: {url: `/benefits/${planPeriodId}/ancillary_benefits`, title: 'Ancillary Overview'},
        ren_benefits_ancillary_benefits_details: {url: `/benefits/${planPeriodId}/ancillary_benefits_details`, title: 'Ancillary Details'},
        ren_eligibility_implementation2: {url: `/eligibility/${planPeriodId}/implementation`, title: 'Overview'},
        ren_eligibility_details: {url: `/eligibility/${planPeriodId}/details`, title: 'Eligibility files'},
        ren_client_review_client_review_core: {url: '/client_review/client_review_core', title: 'Medical Plans'},
        ren_client_review_client_review_ancillary: {url: '/client_review/client_review_ancillary', title: 'Benefits Wallet'},
        // IMPLEMENTATION steps
        impl_overview: {
            eta: '20 min',
            url: '/overview',
            title: 'Implementation Overview',
            description: 'Provide quick, high level information on your company and benefits for the HealthJoy team to begin setting up your HealthJoy program.'
        },
        impl_kickoff_call: {
            url: '/kickoff_call',
            title: 'Attend Kickoff Call',
            description: 'Attend a kickoff call with your HealthJoy Implementation Manager to start to plan for an effective HealthJoy launch. <span class="editing-advice">Click the button here to view your Implementation Manager’s calendar and select a time.</span>'
        },
        impl_gathering_benefits: {
            url: '/gathering_benefits',
            title: 'Information Gathering - Benefits',
            description: 'Provide detailed benefits information so that we can service your employees and their families effectively. This includes uploading plan documents and personalizing their benefits wallet.'
        },
        impl_gathering_eligibility: {
            url: '/gathering_eligibility',
            title: 'Information Gathering - Eligibility',
            description: 'Upload census and other applicable files to ensure that everyone who is eligible for HealthJoy gets access and sees their personal benefits from day 1.'
        },
        impl_benefits_review: {
            url: '/benefits_review',
            title: 'Benefits Review',
            description: 'Review each Benefits Wallet card and Medical Plan Summary in Benefits Preview. <span class="editing-advice">Click the circles below to indicate you have reviewed each section.</span>'
        },
        impl_eligibility_review: {
            url: '/eligibility_review',
            title: 'Eligibility Review',
            description: 'Search individual employees in Benefits Preview to see that we are mapping employees to the right benefits. <span class="editing-advice">Click the circle below to indicate you have reviewed eligibility.</span>'
        },
        impl_launch_preparation: {
            url: '/launch_preparation',
            title: 'Launch Preparation',
            description: 'Complete your final review for benefits and eligibility. Please also check the “Employee Communication” circle below once you have completed best practices for building awareness (white-listing the HealthJoy IP Address, sending the pre-launch email to employees, hanging posters, and scheduling educational meetings).'
        },
        impl_contacts_and_company_contacts: {url: '/contacts_and_company/contacts', title: 'Contacts'},
        impl_contacts_and_company_company_information: {url: '/contacts_and_company/company_information', title: 'Company'},
        impl_benefits_core_benefits1: {url: `/benefits/${planPeriodId}/core_benefits`, title: 'Core Benefits'},
        impl_benefits_ancillary_benefits: {url: `/benefits/${planPeriodId}/ancillary_benefits`, title: 'Ancillary Benefits'},
        impl_eligibility_implementation1: {url: `/eligibility/${planPeriodId}/implementation`, title: 'Eligibility'},
        impl_kickoff_call_schedule_call: {url: '/kickoff_call/schedule_call', title: 'Schedule Call'},
        impl_kickoff_call_call_completed: {url: '/kickoff_call/call_completed', title: 'Call Completed'},
        impl_benefits_core_benefits2: {url: `/benefits/${planPeriodId}/core_benefits`, title: 'Overview'},
        impl_benefits_core_benefits_details: {url: `/benefits/${planPeriodId}/core_benefits_details`, title: 'Core'},
        impl_benefits_ancillary_benefits_details: {url: `/benefits/${planPeriodId}/ancillary_benefits_details`, title: 'Ancillary'},
        impl_eligibility_implementation2: {url: `/eligibility/${planPeriodId}/implementation`, title: 'Overview'},
        impl_eligibility_details: {url: `/eligibility/${planPeriodId}/details`, title: 'Eligibility files'},
        impl_client_review_client_review_core: {url: '/client_review/client_review_core', title: 'Core'},
        impl_client_review_client_review_ancillary: {url: '/client_review/client_review_ancillary', title: 'Ancillary'},
        impl_client_review_client_review_eligibility: {url: '/client_review/client_review_eligibility', title: 'Eligibility'},
        impl_launch_preparation_benefits_approved: {url: '/launch_preparation/benefits_approved', title: 'Benefits Approved'},
        impl_launch_preparation_eligibility_approved: {url: '/launch_preparation/eligibility_approved', title: 'Eligibility Approved'},
        impl_launch_preparation_communication: {url: '/launch_preparation/communication', title: 'Communication Done'},
        impl_launch_preparation_ready: {url: '/launch_preparation/ready', title: 'Ready to Launch'}
    };
    // FYI: omit step urls when no planPeriodId passed (03.02.22, Yuri)
    return planPeriodId
        ? steps
        : Object.entries(steps).reduce((acc, [key, {url, ...value}]) => {
            acc[key] = value;
            return acc;
        }, {});
};

export const getTimelineStepNotification = (due_date, type) => {
    const NOTIFICATION_OFFSET_DAYS = 21;

    if (!due_date) {
        return null;
    }

    const notifications = {
        impl_gathering_benefits: {
            title: 'HealthJoy Review:',
            description: 'Our team needs 3 weeks to review your documents and process the information. Please make sure you have filled out all required sections in order to meet your due dates.',
            date: addDays(due_date, NOTIFICATION_OFFSET_DAYS)
        },
        impl_gathering_eligibility: {
            title: 'HealthJoy Review:',
            description: 'Our team needs 3 weeks to review your documents and process the information. Please make sure you have filled out all required sections in order to meet your due dates.',
            date: addDays(due_date, NOTIFICATION_OFFSET_DAYS)
        }
    };

    return notifications[type] || null;
};

export const getEnhancedTimelineSteps = ({steps = [], ...rest}) => {
    const stepsStatics = getTimelineStepsWithUrl(rest.plan_period_id);
    const addStepStatic = ({timeline_id, parent_id, ...step}) => {
        const enhancedStep = {
            title: null,
            description: null,
            eta: null,
            timeline: timeline_id,
            parent: parent_id,
            notification: getTimelineStepNotification(step.due_date, step.type),
            ...step,
            ...stepsStatics[step.type]
        };

        return step.children.length
            ? {...enhancedStep, children: step.children.map(addStepStatic)}
            : enhancedStep;
    };

    return {
        ...rest,
        steps: steps.map(addStepStatic)
    };
};

export const getDefaultSFTPCredentialsInfo = hostType => {
    return {
        id: undefined,
        is_multi_client_feed: false,
        is_ben_admin: false,
        settings: [
            {
                type: SFTP_SETTINGS_TYPES.memberEligibility,
                path: equal(hostType, SFTP_HOST_TYPES.tpa) ? '/eligibility/processed' : '/incoming'
            },
            {type: SFTP_SETTINGS_TYPES.financialEligibility},
            {type: SFTP_SETTINGS_TYPES.cobraEligibility},
            {type: SFTP_SETTINGS_TYPES.reporting}
        ]
    };
};

export const getRxPlanCopiedFromMedical = (rxPlanDetails, medicalPlanDetails) => {
    return {
        carrier_name: medicalPlanDetails.pharmacy_benefit_manager,
        carrier_logo: medicalPlanDetails.pbm_logo,
        carrier_phone: medicalPlanDetails.pbm_member_services_phone,
        carrier_url: medicalPlanDetails.formulary_url,
        carrier_url_alias: medicalPlanDetails.formulary_url_alias,
        documents: [],
        blocks: [
            // FYI: blocks[0] and blocks[1] are default (Title Info and Paragraph) so we can rely on data we get from BE and just replace content (14.09.2023, Oleh)
            {
                ...rxPlanDetails.blocks[0],
                widgets: [
                    {...rxPlanDetails.blocks[0].widgets[0], content: 'Rx'},
                    {...rxPlanDetails.blocks[0].widgets[1], content: getEncodedHtml(medicalPlanDetails.drug_list_name)}
                ]
            },
            {...rxPlanDetails.blocks[1], widgets: [{...rxPlanDetails.blocks[1].widgets[0], content: ''}]},
            {
                cid: generateUniqueId(),
                title: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS[PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.twoColumnTable],
                type: 'custom',
                widgets: [
                    {
                        cid: generateUniqueId(),
                        type: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.twoColumnTable,
                        content: [
                            {cid: generateUniqueId(), value: 'Member Name'},
                            {cid: generateUniqueId(), value: '', variables: {content: '${profile_full_name}', content_type: 'variable', conditions: null}},
                            {cid: generateUniqueId(), value: 'Member ID'},
                            {cid: generateUniqueId(), value: '', variables: {content: '${medical_plan_member_id}', content_type: 'variable', conditions: null}}
                        ]
                    }
                ]
            },
            {
                cid: generateUniqueId(),
                title: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_LABELS[PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.twoColumnTable],
                type: 'custom',
                widgets: [
                    {
                        cid: generateUniqueId(),
                        type: PLAN_ADVANCED_CUSTOMIZATION_WIDGET_TYPES.twoColumnTable,
                        content: [
                            ...(
                                medicalPlanDetails.rx_group ? [
                                    {cid: generateUniqueId(), value: 'RX Group Number'},
                                    {cid: generateUniqueId(), value: getEncodedHtml(medicalPlanDetails.rx_group)}
                                ] : []
                            ),
                            {cid: generateUniqueId(), value: 'RX BIN'},
                            {cid: generateUniqueId(), value: getEncodedHtml(medicalPlanDetails.rx_bin)},
                            {cid: generateUniqueId(), value: 'RX PCN'},
                            {cid: generateUniqueId(), value: getEncodedHtml(medicalPlanDetails.rx_pcn)}
                        ]
                    }
                ]
            }
        ]
    };
};

export const getImportConfigJSONFields = configType => {
    const BASE_JSON_FIELDS = [
        'cobra_condition', 'relationship_mapper', 'inherit_core_condition', 'allowable_condition', 'not_allowable_condition',
        'allowable_condition_or', 'ignore_termination_date_condition', 'ignore_fields', 'ignore_members', 'cobra_condition'
    ];

    return equal(configType, IMPORT_CONFIG_TYPES.tpa)
        ? BASE_JSON_FIELDS
        : [...BASE_JSON_FIELDS, 'key_custom_fields', 'xml_mapping', 'xml_parse_only_fields', 'xml_voluntary_custom_mapping'];
};

export const getStringifiedImportConfigData = (jsonFields, data) => {
    const enhancedImportConfig = jsonFields.reduce((acc, field) => {
        const value = data.import_config[field];
        const stringifiedValue = value ? JSON.stringify(value) : null;
        const enhancedValue = ['{}', '[]', '[{}]'].includes(stringifiedValue) ? null : stringifiedValue;

        return {...acc, [field]: enhancedValue};
    }, {});

    return {
        ...data,
        import_config: {
            ...data.import_config,
            ...enhancedImportConfig
        }
    };
};

export const getParsedImportConfigData = (jsonFields, data) => {
    const enhancedImportConfig = jsonFields.reduce((acc, field) => {
        const value = data.import_config[field];
        const parsedValue = value ? JSON.parse(value) : null;

        return {...acc, [field]: parsedValue};
    }, {});

    return {
        ...data,
        import_config: {
            ...data.import_config,
            ...enhancedImportConfig
        }
    };
};

export const getTPAGroupTweakValidation = group => ({
    ...TPA_GROUP_TWEAK_JSON_FIELDS.reduce((acc, field) => {
        const validateFunc = TPA_GROUP_TWEAK_JSON_OBJECT_FIELDS.some(getEqual(field)) ? validateJSONObject : validateJSON;

        return ({
            ...acc,
            [field]: validateFunc(group?.[field])
        });
    }, {})
});

export const getRecentlyViewedClients = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.recentlyViewedClients)) || [];

export const setRecentlyViewedClients = clients => {
    const existedClients = getRecentlyViewedClients();
    const filteredClients = existedClients.filter(({id}) => !clients.some(getEqual(id, 'id')));
    const updatedClients = [...clients, ...filteredClients];

    localStorage.setItem(LOCAL_STORAGE_ITEMS.recentlyViewedClients, JSON.stringify(updatedClients));
};

export const getAncillaryPlanLabel = ({category, categoryIndex, cardTitle}) => {
    const {label: categoryLabel} = ANCILLARY_PLANS_DATA_BY_CATEGORY[category];

    const isCardTitleAvailable = cardTitle && categoryLabel && !equal(cardTitle.toLowerCase().trim(), categoryLabel.toLowerCase());
    const formattedCardTitle = isCardTitleAvailable ? `(${cardTitle.trim()})` : '';

    return [categoryLabel, categoryIndex, formattedCardTitle].filter(Boolean).join(' ');
};

export const getLatestRegularConfig = configs => {
    return Object.entries(configs)
        .filter(([key]) => key.startsWith('Regular'))
        .reduce((acc, [key, value]) => {
            return acc.config_activation_date > value.config_activation_date ? acc : value;
        }, {});
};

export const getIndividualConfig = (isRenewal, configs) => {
    if (isRenewal) {
        const [configKey] = Object.keys(configs).filter(key => key.startsWith('Renewal'));

        return configs[configKey];
    }

    if (Object.keys(configs).filter(key => key.startsWith('Implementation')).length) {
        const [configKey] = Object.keys(configs).filter(key => key.startsWith('Implementation'));

        return configs[configKey];
    }

    return getLatestRegularConfig(configs);
};

export const getImportThresholdsMassMembersText = massMembersChanges => {
    const massMembersChangedList = Object.entries(massMembersChanges).map(([key, value]) => value && ELIGIBILITY_IMPORT_MASS_MEMBERS_LABELS[key]).filter(Boolean);

    return formatListByLocales(massMembersChangedList, {style: 'long', type: 'conjunction'});
};

export const getIsImportSessionThresholds = ({thresholds}) => !!getImportThresholdsMassMembersText(thresholds?.mass_data_changes) || !!thresholds?.members_pending_termination.count || !!thresholds?.migrated_members.count;

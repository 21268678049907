import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import {POPUP_TYPES} from '@frontend/ui-kit/Components/Popup';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ContactInfoBasicForm from '../ContactInfoBasicForm';
import CustomizablePopup from '../../shared/CustomizablePopup';
import withPopup from '../../../HOC/withPopup';
import {showToast} from '../../../actions/shared';
import {requestContactAdding} from '../../../actions/contacts';
import {getActiveCompanyName} from '../../../selectors/general';
import {ROUTES, FORMS} from '../../../constants';

const POPUP_ID = 'newContactInfoPopup';

const NewContactInfoForm = ({openPopup, closePopup}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const companyName = useSelector(getActiveCompanyName);
    const [initialValues, setInitialValues] = useState({});

    const onAddAdmin = (values, handleSubmit) => {
        const actionBar = (
            <React.Fragment>
                <Button onClick={closePopup} type={BUTTON_TYPES.secondary}>Cancel</Button>
                <Button onClick={handleSubmit} type={BUTTON_TYPES.primary}>Yes, Add Contact</Button>
            </React.Fragment>
        );
        const content = (
            <React.Fragment>
                Are you sure you want to add this contact?&nbsp;
                <Text type={TEXT_TYPES.bodyBold} isInline>{values.first_name} {values.last_name}</Text> will now be able to access PII/PHI.
                By proceeding, you are granting access to Member PII/PHI for <Text type={TEXT_TYPES.bodyBold} isInline>{companyName}</Text>.
            </React.Fragment>
        );
        const popupProps = {title: 'Add Contact Confirmation', content, actionBar};
        const children = <CustomizablePopup {...popupProps}/>;

        openPopup({type: POPUP_TYPES.simple, children});
    };

    const onSubmit = async values => {
        const {data, isSuccess, submissionErrors} = await dispatch(requestContactAdding({...values}));

        if (!isSuccess) {
            closePopup();
            return submissionErrors;
        }

        setInitialValues(data.user);
    };

    const onSubmitSuccess = () => {
        navigate(ROUTES.contacts);
        dispatch(showToast({content: 'Contact added and invited!'}));
    };

    const basicFormProps = {initialValues, formName: FORMS.newContactInfo, isExisted: false, onSubmit, onSubmitSuccess, onAddAdmin};

    return <ContactInfoBasicForm {...basicFormProps}/>;
};

NewContactInfoForm.propTypes = {
    openPopup: PropTypes.func.isRequired,
    closePopup: PropTypes.func.isRequired
};

export {NewContactInfoForm as TestableNewContactInfoForm};
export default withPopup(POPUP_ID)(NewContactInfoForm);

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Table from '@frontend/ui-kit/Components/Table';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import {ELIGIBILITY_IMPORT_STATUSES} from '../../../../constants';
import {equal, isEmpty} from '../../../../utils';

const THRESHOLD_KEYS = {
    termination: 'termination',
    migration: 'migration'
};

const Thresholds = ({import_info: importInfo = {}, general_statistics: generalStatistics = {}}) => {
    const navigate = useNavigate();
    const {search, pathname} = useLocation();
    const tableData = useMemo(() => {
        const {migrated_members: {data: migratedMembers, count: migratedMembersCount} = {}, members_pending_termination: {data: terminationMembers} = {}} = importInfo?.thresholds || {};

        const getCountContent = members => {
            const membersPercentage = Math.round((members / generalStatistics.all_members_count) * 100);

            return `${members} members ${isFinite(membersPercentage) ? `(${membersPercentage}%)` : ''}`;
        };

        return [
            ...terminationMembers?.length ? [{description: 'Termination', key: THRESHOLD_KEYS.termination, explanation: <span>Review the termination surge for this import. Download pending termination list to confirm with appropriate team or go to <span className='pending-termination'>Pending Terminations</span> tab to confirm member status and/or view full list.</span>, count: getCountContent(terminationMembers.length)}] : [],
            ...migratedMembers?.length ? [{description: 'Migration', key: THRESHOLD_KEYS.migration, explanation: 'Migrated from another company', count: getCountContent(migratedMembersCount)}] : []
        ];
    }, [importInfo, generalStatistics]);
    const isEditable = useMemo(() => equal(importInfo?.status, ELIGIBILITY_IMPORT_STATUSES.pending_confirmation), [importInfo]);

    const getTableColumns = () => {
        const getActions = ({value: key}) => {
            const onGoToThresholds = () => navigate(`${pathname}/thresholds${search}#${key}`);

            return (
                <Button onClick={onGoToThresholds} type={BUTTON_TYPES.tertiary} data-testid='verify-button'>View</Button>
            );
        };

        return [
            {Header: 'Description', accessor: 'description'},
            {Header: 'Explanation', accessor: 'explanation'},
            {Header: '# of EEs', accessor: 'count', width: 120},
            {Header: 'Actions', accessor: 'key', width: 120, Cell: getActions}
        ];
    };

    const tableProps = {
        data: tableData,
        isFilterable: false,
        isSortable: false,
        isCellTooltip: false,
        className: 'mt-8',
        columns: getTableColumns()
    };

    return (
        <div className='thresholds'>
            {!isEmpty(tableData) && isEditable && <Alert className='mb-10' type={ALERT_TYPES.danger} description='Review the threshold surges below.'/>}

            <Heading type={HEADING_TYPES['4']} className='mt-20'>Thresholds ({tableData.length})</Heading>

            <Table {...tableProps}/>
        </div>
    );
};

Thresholds.propTypes = {
    import_info: PropTypes.shape({
        status: PropTypes.string
    }),
    general_statistics: PropTypes.shape({
        all_members_count: PropTypes.number
    })
};

export {Thresholds as TestableThresholds};
export default Thresholds;

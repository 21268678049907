export default class ReduxActionsAdapter {
    constructor() {
        this.nextAction = null;
        this.onBlock = null;
    }

    block = onBlock => {
        this.onBlock = onBlock;

        return this.unblock;
    };

    unblock = () => this.onBlock = null;

    setAction = action => this.nextAction = action;

    checkIsBlocked = () => this.onBlock && !this.onBlock(this.nextAction);
}

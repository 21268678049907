import {getActiveCompany} from '../selectors/general';
import {
    RECEIVE_REPORTING_INFO,
    SET_REPORTING_PLAN_PERIOD,
    DOWNLOAD_ACTIVATION_REPORT,
    DOWNLOAD_PERFORMANCE_REPORT,
    DOWNLOAD_REPORT,
    REQUEST_SEARCHED_MEMBERS
} from './actionTypes';
import apiServices from '../apiServices';
import {LOCAL_STORAGE_ITEMS, HTTP_RESPONSE_TYPES} from '../constants';
import {saveFile} from '../utils';

const receiveReportingInfo = info => ({type: RECEIVE_REPORTING_INFO, info});
const downloadActivationReport = fileDetails => ({type: DOWNLOAD_ACTIVATION_REPORT, fileDetails});
const downloadPerformanceReport = fileDetails => ({type: DOWNLOAD_PERFORMANCE_REPORT, fileDetails});
const downloadReport = fileDetails => ({type: DOWNLOAD_REPORT, fileDetails});
const requestSearchedMembers = ({query}) => ({type: REQUEST_SEARCHED_MEMBERS, query}); // FYI: action for analyticsTracker (Vlad, 06.07.20)

export const setReportingPlanPeriod = planPeriod => {
    localStorage.setItem(LOCAL_STORAGE_ITEMS.reportingPlanPeriod, planPeriod);

    return {type: SET_REPORTING_PLAN_PERIOD, planPeriod};
};

export const requestDownloadReports = reportingPlanPeriod => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data: {files = []}, isSuccess} = await apiServices.getDownloadReports({companyAlias, reportingPlanPeriod});

    if (!isSuccess) {
        return false;
    }

    dispatch(receiveReportingInfo({reports: files}));
};

export const requestMembers = params => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    dispatch(requestSearchedMembers(params));

    const {data, isSuccess} = await apiServices.getSearchedCompanyEmployees({companyId, ...params});
    const {employees: members = [], count = 0, show_location: isLocationShowed} = isSuccess ? data : {};

    dispatch(receiveReportingInfo({member_activation: {members}}));

    return {members, count, isLocationShowed, isSuccess};
};

export const requestMembersReport = () => async (dispatch, getState) => {
    const state = getState();
    const {id: companyId} = getActiveCompany(state);

    const {data: reportFile, isSuccess, headers} = await apiServices.getCompanyEmployeesReport({companyId});

    if (!isSuccess) {
        return false;
    }

    const {'content-disposition': disposition} = headers;
    const [name] = disposition.split('=').reverse();

    saveFile(reportFile, name);

    dispatch(downloadActivationReport({name}));
};

export const requestPerformanceReport = fileDetails => async dispatch => {
    const {url, name} = fileDetails;
    const {data: reportFile, isSuccess} = await apiServices.getFromThirdParty(url, null, {responseType: HTTP_RESPONSE_TYPES.blob});

    if (!isSuccess) {
        return false;
    }

    saveFile(reportFile, name);

    dispatch(downloadPerformanceReport(fileDetails));
};

export const requestReportingDashboardHiddenTiles = dashboardId => async () => {
    const {data, isSuccess} = await apiServices.getReportingDashboardHiddenTiles({dashboardId});
    const hiddenTiles = isSuccess ? data.tiles_ids : [];

    return {hiddenTiles, isSuccess};
};

export const requestReportDownloading = (dashboardId, filters) => async dispatch => {
    const {data: reportFile, isSuccess, headers} = await apiServices.setReportDownloading({dashboardId, filters});

    if (!isSuccess) {
        return false;
    }

    const {'content-disposition': disposition} = headers;
    const [name] = disposition.split('=').reverse();

    saveFile(reportFile, name);

    dispatch(downloadReport({name}));
};

import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import PendingMigrationsMembers from '../PendingMigrationsMembers';
import PendingTerminationsMembers from '../PendingTerminationsMembers';
import {requestEligibilityImportSessionStats} from '../../../actions/adminPortal';
import {ROUTES} from '../../../constants';
import {getEqual} from '../../../utils';

const THRESHOLDS = [
    {key: '#migration', component: PendingMigrationsMembers},
    {key: '#termination', component: PendingTerminationsMembers}
];

const EligibilityImportThresholds = () => {
    const navigate = useNavigate();
    const [importData, setImportData] = useState({});
    const {id: importId} = useParams();
    const {hash: thresholdType} = useLocation();
    const dispatch = useDispatch();
    const {component} = useMemo(() => THRESHOLDS.find(getEqual(thresholdType, 'key')), [thresholdType]);
    const Component = component || {};

    useEffect(() => {
        (async () => {
            const {isSuccess, data: importData} = await dispatch(requestEligibilityImportSessionStats(importId));

            if (!isSuccess) {
                return navigate(ROUTES.eligibilityImports);
            }

            setImportData(importData);
        })();
    }, [importId, dispatch]);

    return (
        <div className='mt-15'>
            <Component importData={importData}/>
        </div>
    );
};

export {EligibilityImportThresholds as TestableEligibilityImportThresholds};
export default EligibilityImportThresholds;

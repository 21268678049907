import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Wizard from '../../../Containers/shared/Wizard';
import PlanStep from '../CoreWizardSteps/PlanStep';
import MedicalTraitsStep from '../CoreWizardSteps/MedicalTraitsStep';
import withSubStepCompletion from '../../../HOC/withSubStepCompletion';
import {getProfilePermissions} from '../../../selectors/general';
import {CORE_PLAN_LABELS_BY_TYPE, CORE_PLAN_TYPES, ROUTES} from '../../../constants';

const WIZARD_STEPS = [
    {StepComponent: PlanStep, stepName: CORE_PLAN_TYPES.medical, stepTitle: CORE_PLAN_LABELS_BY_TYPE.medical},
    {StepComponent: PlanStep, stepName: CORE_PLAN_TYPES.dental, stepTitle: CORE_PLAN_LABELS_BY_TYPE.dental},
    {StepComponent: PlanStep, stepName: CORE_PLAN_TYPES.vision, stepTitle: CORE_PLAN_LABELS_BY_TYPE.vision},
    {StepComponent: MedicalTraitsStep, stepName: CORE_PLAN_TYPES.additional, stepTitle: CORE_PLAN_LABELS_BY_TYPE.additional}
];

const CoreWizard = ({onComplete}) => {
    const profilePermissions = useSelector(getProfilePermissions);
    const navigate = useNavigate();

    const wizardProps = {
        steps: WIZARD_STEPS,
        onSuccess: profilePermissions.timeline_edit ? onComplete : () => navigate(ROUTES.root),
        isReadonly: !profilePermissions.core_plan_edit
    };

    return (
        <div className='benefits-core-form'>
            <Wizard {...wizardProps}/>
        </div>
    );
};

CoreWizard.propTypes = {
    onComplete: PropTypes.func.isRequired
};

export {CoreWizard as TestableCoreWizard};
export default withSubStepCompletion(CoreWizard);

import React from 'react';
import {useSelector} from 'react-redux';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';
import Pages from './Pages';
import AuthorizationLayout from './Components/shared/AuthorizationLayout';
import DashboardLayout from './Components/shared/DashboardLayout';
import AdminPortalLayout from './Components/shared/AdminPortalLayout';
import {getAuthorizationStatus} from './selectors/authorization';
import {ROUTES, IS_IE_BROWSER} from './constants';

const Router = () => {
    const isAuthorized = useSelector(getAuthorizationStatus);

    return (
        <RouterProvider router={
            createBrowserRouter(
                createRoutesFromElements(
                    <React.Fragment>
                        {IS_IE_BROWSER && <Route path='*' element={<Pages.IESunset/>}/>}

                        {!IS_IE_BROWSER && (
                            <React.Fragment>
                                {/* FYI: artificial routes for correct redirection to current home page depending on conditions */}
                                {isAuthorized && (
                                    <Route element={<DashboardLayout/>}>
                                        <Route path={ROUTES.root} end/>
                                    </Route>
                                )}
                                {!isAuthorized && (
                                    <Route element={<AuthorizationLayout/>}>
                                        <Route path={ROUTES.root} end element={<Pages.Login/>}/>
                                    </Route>
                                )}
                                <Route element={<AuthorizationLayout/>}>
                                    <Route path={ROUTES.login} element={<Pages.Login/>}/>
                                    <Route path={ROUTES.locked} end element={<Pages.Locked/>}/>
                                    <Route path={ROUTES.password} element={<Pages.Activation/>}/>
                                    <Route path={ROUTES.activation} element={<Pages.Activation/>}/>
                                    <Route path={ROUTES.passwordRecovery} element={<Pages.PasswordRecovery/>}/>
                                    <Route path={ROUTES.verification} element={<Pages.Verification/>}/>
                                    <Route path={ROUTES.resendCode} element={<Pages.ResendCode/>}/>
                                </Route>

                                <Route path={ROUTES.signOut} element={<Pages.SignOut/>}/>

                                <Route element={<AdminPortalLayout/>}>
                                    {/* FYI: artificial route for correct redirection to plan manager or root */}
                                    <Route end path={ROUTES.adminPortalRoot}/>
                                    <Route end path={ROUTES.planManager} element={<Pages.PlanManager/>}/>
                                    <Route path={`${ROUTES.planManager}/:id`} element={<Pages.PlanManagerDetails/>}/>
                                    <Route end path={ROUTES.carrierManager} element={<Pages.CarrierManager/>}/>
                                    <Route end path={ROUTES.createCarrier} element={<Pages.CarrierManagerCreateDetails/>}/>
                                    <Route path={`${ROUTES.carrierManager}/:id`} element={<Pages.CarrierManagerDetails/>}/>
                                    <Route end path={ROUTES.timelineTemplates} element={<Pages.TimelineTemplates/>}/>
                                    <Route end path={ROUTES.createTimelineTemplate} element={<Pages.TimelineTemplatesCreation/>}/>
                                    <Route path={`${ROUTES.timelineTemplates}/:id`} element={<Pages.TimelineTemplatesDetails/>}/>
                                    <Route end path={ROUTES.appConfiguration} element={<Pages.AppConfiguration/>}/>
                                    <Route end path={`${ROUTES.appConfiguration}/:type/:companyAlias`} element={<Pages.AppConfigurationDetails/>}/>
                                    <Route end path={ROUTES.recommendationsManager} element={<Pages.RecommendationsManager/>}/>
                                    <Route end path={ROUTES.createRecommendationCard} element={<Pages.RecommendationCardCreation/>}/>
                                    <Route end path={`${ROUTES.recommendationsManager}/:id`} element={<Pages.RecommendationCardDetails/>}/>
                                    <Route end path={ROUTES.eligibilityImports} element={<Pages.EligibilityImports/>}/>
                                    <Route end path={ROUTES.importsTpa} element={<Pages.AllClients/>}/>
                                    <Route end path={ROUTES.importsIndividual} element={<Pages.AllClients/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias`} element={<Pages.IndividualGroup/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/group_config`} element={<Pages.IndividualGroupConfiguration/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/archived_group_config/:id`} element={<Pages.IndividualGroupConfigurationArchived/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/validation_report/:id`} element={<Pages.IndividualValidationReport/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/validation_report/:id/thresholds`} element={<Pages.IndividualValidationReportThresholds/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/validation_report/:id/blocker_errors/:column_name`} element={<Pages.IndividualValidationReportBlockerErrorsDetails/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/verification_report/:id`} element={<Pages.IndividualVerificationReport/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/verification_report/:id/core_plans`} element={<Pages.IndividualVerificationReportDetailedCorePlans/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/verification_report/:id/card_links`} element={<Pages.IndividualVerificationReportCardLinks/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/pending_terminations`} element={<Pages.PendingTerminationsMembers/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/pending_migrations`} element={<Pages.PendingMigrationsMembers/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/blocker_errors_report/:id`} element={<Pages.ImportBlockerErrorsReport/>}/>
                                    <Route end path={`${ROUTES.importsIndividual}/:group_alias/blocker_errors_report/:id/:column_name`} element={<Pages.ImportBlockerErrorsReportDetails/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner`} element={<Pages.TPAPartner/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/tpa_config`} element={<Pages.TPAPartnerConfiguration/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/archived_tpa_config/:id`} element={<Pages.TPAPartnerConfigurationArchived/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/all`} element={<Pages.TPAGroupsAll/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/configure_tpa_groups`} element={<Pages.ConfigureTPAGroups/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/:group_alias/group_config`} element={<Pages.TPAAutoimportGroupConfiguration/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/:group_alias/blocker_errors_report/:id`} element={<Pages.TPAGroupBlockerErrorsReport/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/:group_alias/blocker_errors_report/:id/:column_name`} element={<Pages.TPAGroupBlockerErrorsReportDetails/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/:group`} element={<Pages.TPAGroupDetails/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/pre_processing_report/:session_id`} element={<Pages.PreprocessingReport/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/pre_processing_report/:session_id/:error_index`} element={<Pages.PreprocessingReportError/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/validation_report/:id`} element={<Pages.TPAValidationReport/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/validation_report/:id/thresholds`} element={<Pages.TPAValidationReportThresholds/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/verification_report/:id`} element={<Pages.TPAVerificationReport/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/verification_report/:id/core_plans`} element={<Pages.TPAVerificationReportDetailedCorePlans/>}/>
                                    <Route end path={`${ROUTES.importsTpa}/:partner/verification_report/:id/card_links`} element={<Pages.TPAVerificationReportCardLinks/>}/>
                                </Route>

                                <Route element={<DashboardLayout/>}>
                                    <Route path={ROUTES.externalRedirection} element={<Pages.ZendeskRedirection/>}/>

                                    <Route path={ROUTES.timeline} element={<Pages.Timeline/>}/>
                                    <Route path={ROUTES.timelineGeneration} element={<Pages.TimelineGeneration/>}/>

                                    <Route path={ROUTES.companyInfo} element={<Pages.CompanyInfo/>}/>
                                    <Route path={ROUTES.contactInfo} element={<Pages.ContactInfo/>}/>
                                    <Route path={ROUTES.baseContactInfo} element={<Pages.BaseContactInfo/>}/>
                                    <Route path={ROUTES.contacts} element={<Pages.Contacts/>}/>

                                    <Route path={`${ROUTES.benefits}/:planPeriodId/core_benefits`} element={<Pages.CoreBenefits/>}/>
                                    <Route path={`${ROUTES.benefits}/:planPeriodId/core_benefits_details`} element={<Pages.CoreBenefitsDetails/>}/>
                                    <Route path={`${ROUTES.benefits}/:planPeriodId/ancillary_benefits`} element={<Pages.AncillaryBenefits/>}/>
                                    <Route path={`${ROUTES.benefits}/:planPeriodId/ancillary_benefits_details`} element={<Pages.AncillaryBenefitsDetails/>}/>
                                    <Route path={`${ROUTES.benefits}/:planPeriodId/preview`} element={<Pages.BenefitsPreview/>}/>

                                    <Route path={`${ROUTES.eligibility}/:planPeriodId/implementation`} element={<Pages.EligibilityOverview/>}/>
                                    <Route path={`${ROUTES.eligibility}/:planPeriodId/details`} element={<Pages.EligibilityDetails/>}/>
                                    <Route path={`${ROUTES.eligibility}/:planPeriodId/export`} element={<Pages.EligibilityExport/>}/>

                                    <Route path={ROUTES.benefitsOverview} element={<Pages.BenefitsOverview/>}/>
                                    <Route path={ROUTES.openEnrollment} element={<Pages.OpenEnrollment/>}/>

                                    <Route path={ROUTES.reporting} end/>
                                    <Route path={ROUTES.bookOfBusinessReporting} element={<Pages.BookOfBusiness/>}/>
                                    <Route path={ROUTES.costContainmentReporting} element={<Pages.CostContainment/>}/>
                                    <Route path={`${ROUTES.benefitsUsageReporting}/:type?`} element={<Pages.BenefitsUsage/>}/>
                                    <Route path={ROUTES.completeRoiReporting} element={<Pages.CompleteRoi/>}/>
                                    <Route path={ROUTES.memberActivationReporting} element={<Pages.MemberActivation/>}/>
                                    <Route path={ROUTES.reportDownloadsReporting} element={<Pages.DownloadReports/>}/>

                                    <Route path={`${ROUTES.educationAndMarketing}/:id`} element={<Pages.EducationAndMarketing/>}/>

                                    {/* FYI: make sure that routes are equal to listed within UnavailabilityMap (general selector) (08.09.2020, Oleh) */}
                                    <Route end path={ROUTES.broadcasterWelcome} element={<Pages.BroadcasterWelcome/>}/>
                                    <Route path={`${ROUTES.broadcastEditor}/:id?`} element={<Pages.BroadcastEditor/>}/>
                                    <Route path={`${ROUTES.broadcastDetails}/:id`} element={<Pages.BroadcastDetails/>}/>
                                </Route>

                                <Route path={ROUTES.pageNotFound} element={<Pages.PageNotFound/>}/>
                                <Route path={`${ROUTES.serverError}/:errorCode`} element={<Pages.ServerError/>}/>

                                <Route path='*' element={<Pages.PageNotFound/>}/>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )
            )
        }/>
    );
};

export default Router;

import {
    SET_ACTIVE_BROADCASTS_TAB,
    CLEAR_BROADCAST,
    RECEIVE_BROADCAST,
    RECEIVE_BROADCASTS,
    RECEIVE_BROADCAST_TEMPLATES,
    RECEIVE_WALLET_LINKS,
    RECEIVE_AUDIENCE,
    SET_BROADCAST_TEMPLATE
} from '../actions/actionTypes';
import {LOCAL_STORAGE_ITEMS} from '../constants';

export const initialState = {
    activeTab: 0,
    broadcasts: [],
    broadcastTemplates: [],
    broadcast: {},
    broadcastTemplate: JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS.broadcastTemplate)) || null,
    walletLinks: []
};

export default (state = initialState, {type, ...payload}) => {
    switch (type) {
        case SET_BROADCAST_TEMPLATE: {
            const {broadcastTemplate} = payload;

            return {...state, broadcastTemplate};
        }

        case RECEIVE_BROADCAST_TEMPLATES: {
            const {broadcastTemplates} = payload;

            return {...state, broadcastTemplates};
        }

        case RECEIVE_BROADCASTS: {
            const {broadcasts} = payload;

            return {...state, broadcasts};
        }

        case SET_ACTIVE_BROADCASTS_TAB: {
            const {activeTab} = payload;

            return {...state, activeTab};
        }

        case RECEIVE_BROADCAST: {
            const {broadcast} = payload;

            return {...state, broadcast};
        }

        case CLEAR_BROADCAST: {
            const broadcast = {};

            return {...state, broadcast};
        }

        case RECEIVE_WALLET_LINKS: {
            const {walletLinks} = payload;

            return {...state, walletLinks};
        }

        case RECEIVE_AUDIENCE: {
            const {broadcastAudience} = payload;

            return {...state, broadcastAudience};
        }

        default: {
            return state;
        }
    }
};

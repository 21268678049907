import ActionNavigationController, {actionNavigationControllerMiddleware} from './ActionNavigationController';
import RouterNavigationController from './RouterNavigationController';

const NavigationController = {
    Action: ActionNavigationController,
    Router: RouterNavigationController
};

export {actionNavigationControllerMiddleware};
export default NavigationController;
